import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  Pressable,
  Image,
} from "react-native";
// import ListShare from "./ListShare";
import { FontAwesome, Ionicons } from "@expo/vector-icons";
import * as DocumentPicker from "expo-document-picker";
import { t } from "i18n-js";
import { useSelector, useDispatch } from "react-redux";
import SearchInput from "../../Elements/SearchInput";
import { MAX_FILES_SIZE } from "@env";
import {
  CREATE_GAME_PERMISSION,
  USER_GAME_NAME,
  USER_ID_IN_GAME,
} from "../../../config.inc";
import InputRadio from "../../../InputRadio";
import UseResponsiveSize from "../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";
import { loading, setErrorMessage, showFile } from "../../../redux/actions";
import onClickEndGameCheck from "../../Elements/onClickEndGameCheck";
import { convertByte } from "../../modules/ConvertByte";
import FileLimitPopup from "../../Elements/FileLimitPopup";
import EditorTiny from "../../editor/EditorTiny";
import TextCustomize from "../../Elements/TextCustomize";
import FullTextTooltip from "../../Elements/FullTextTooltip";

export default function NewPost({ newPost, setNewPost, errMsg, addPost, resetPost }) {

  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();

  const background = useSelector((state) => state.style.background);
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const styles = stylesR(width, height, heightS, widthS, background, direction);

  const { checkEnd } = onClickEndGameCheck();

  const dispatch = useDispatch();

  const gameInfo = useSelector((state) => state.gameInfoArr.gameInfoArr);
  const playerPermission = useSelector((state) => state.gameInfoArr.permission);
  const color = useSelector((state) => state.style.color);
  const images = useSelector((state) => state.style.images);


  const ableToShare =
    sessionStorage.getItem(CREATE_GAME_PERMISSION) === "1" ||
    playerPermission === "1";

  // const [showShareList, setShowShareList] = useState(false);
  // const [showFromList, setShowFromList] = useState(false);

  const [rowsCount, setRowsCount] = useState(1);
  const [shareWithAll, setShareWithAll] = useState(true);
  const [inputfontSize, setInputFontSize] = useState(widthS * 8.5);
  const [imageRatio, setImageRatio] = useState();
  const [mainWidth, setMainWidth] = useState();
  const postRef = useRef();
  const photoRef = useRef(null);
  const videoRef = useRef(null);
  const [showFileLimit, setShowFileLimit] = useState({ photo: false, video: false });

  const openImagesBrowser = () => {
    let types = ["image/jpg", "image/jpeg", "image/png"];
    DocumentPicker.getDocumentAsync({
      multiple: false,
      type: types.join(","),
    }).then((res) => res.assets[0]).then((res) => {
      let size = convertByte(res.size);
      if (types.find((e) => res.mimeType.indexOf(e) >= 0) && size < MAX_FILES_SIZE) {
        Image.getSize(res.uri, (width, height) => setImageRatio(height / width));
        setNewPost({ ...newPost, file: res });
      } else if (size >= MAX_FILES_SIZE) {
        dispatch(
          setErrorMessage(
            true,
            undefined,
            `${t("err_fileSize_mail")}`,
          )
        );
      } else {
        dispatch(
          setErrorMessage(
            true,
            undefined,
            `${t("err_images_social")}`,
          )
        );
      }
    });
  };
  const openVideosBrowser = () => {
    let types = ["audio/mp3", "audio/mpeg", "video/mp4"]
    DocumentPicker.getDocumentAsync({
      type: types.join(","),
    }).then((res) => res.assets[0]).then((res) => {
      let size = convertByte(res.size);
      if (types.find((e) => res.mimeType.indexOf(e) >= 0) && size < MAX_FILES_SIZE) {
        Image.getSize(res.uri, (width, height) => setImageRatio(height / width));
        setNewPost({ ...newPost, file: res });
      } else if (size >= MAX_FILES_SIZE) {
        dispatch(
          setErrorMessage(
            true,
            undefined,
            `${t("err_fileSize_mail")}`,
          )
        );
      } else {
        dispatch(
          setErrorMessage(
            true,
            undefined,
            `${t("err_videos_social")}`,
          )
        );
      }
    });
  };

  const printListshare = () => {
    if (shareWithAll || !newPost.share) {
      return t("all_players");
    } else {
      let sharePrint = "";
      if (newPost.share && newPost.share.length > 10) {
        sharePrint = `${newPost.share.slice(0, 10)}...`;
        let count = newPost.share.match(/,/g).length;
        if (count > 1) {
          sharePrint += ` + ${count - 1}`;
        }
      } else {
        if (newPost.share.length > 0) {
          sharePrint = newPost.share;
        }
      }
      return sharePrint;
    }
  };

  const changePostHandler = async (txt) => {
    if (txt.length >= 100 && inputfontSize === widthS * 8.5)
      setInputFontSize(widthS * 7);
    else if (txt.length < 100 && inputfontSize === widthS * 7)
      setInputFontSize(widthS * 8.5);

    setNewPost({
      ...newPost,
      post: `<p>${txt.replace(/\\n/g, "</br>")}</p>`,
      post_text: txt,
    });
  };

  const addPlayers = (type) => {
    let list = "";
    gameInfo.players.map((e) => {
      if (e[type] !== sessionStorage.getItem(USER_ID_IN_GAME) && e[type] !== sessionStorage.getItem(USER_GAME_NAME)) {
        list += e[type] + ",";
      }
    })
    return list;
  }

  const addAllPlayers = (gameInfoArr) => {
    let ids = "";
    let names = "";
    gameInfoArr &&
      gameInfoArr.players &&
      gameInfoArr.players.forEach((e) => {
        // if (e.is_represented === "0") {
        ids += `${e.id}, `;
        names += `${e.name_for_game}, `;
        // }
      });
    setNewPost({ ...newPost, share_ids: ids, share: names });
    setShareWithAll(true);
  };

  /*
    useEffect(() => {
      if (!newPost.share.length > 0 && !playerPermission == "1") {
        addAllPlayers(gameInfo);
      }
    }, [newPost.share]);
  */
  useEffect(() => {
    if (newPost.post_text.indexOf("\n") >= 0 && rowsCount < 4) {
      let rows = (newPost.post_text.match(/[\n]/g) || []).length;
      setRowsCount(rows + 1);
    }
  }, [newPost.post_text]);

  const fileLimitPopu2p = (r) => {
    let l = parseInt(r?.current?.getBoundingClientRect().width) / 2;
    let t = parseInt(r?.current?.getBoundingClientRect().height) / 2;
    return (
      <View nativeID="files-des" style={[styles.des_container, {
        right: l,
        top: t,
      }]}>
        <View
          style={[
            styles.traingular,
            { borderBottomColor: background.filesDes },
          ]}
        />
        <View
          style={[
            styles.des_view,
            { backgroundColor: background.filesDes },
          ]}
        >
          <Text
            selectable={false}
            style={[styles.des_text, { color: color.filesText }]}
          >
            {"The file size limit is 50MB"}
          </Text>
        </View>
      </View>
    )
  }

  useEffect(() => {
    if (!ableToShare) {
      setNewPost({
        ...newPost,
        from: sessionStorage.getItem(USER_GAME_NAME),
        from_id: sessionStorage.getItem(USER_ID_IN_GAME),
        share_ids: addPlayers("id"),
        share: addPlayers("name_for_game")
      });
    }
  }, [ableToShare, resetPost]);

  return (
    <>
      <View style={[styles.container(playerPermission === "1"), { borderBottomColor: color.border }]}>
        <TextCustomize style={styles.title} fontWeight={600} keyText={"social_new_title"} />
        <View
          onLayout={(event) => setMainWidth(event.nativeEvent.layout.width)}
          style={[
            styles.main(ableToShare),
            {
              borderColor: color.socialPostBorder,
              backgroundColor: background.socialPost,
            },
          ]}
        >
          <View style={styles.top}>
            <Image
              source={{ uri: newPost.from_image ? newPost.from_image : images.user_image }}
              style={styles.user_img}
            />
            <View
              style={[
                styles.top_right,
                { marginTop: ableToShare ? heightS * 7 : 0, writingDirection: direction },
              ]}
            >
              <View style={{
                marginBottom: heightS * -3,
                position: "relative",
                zIndex: 10,
                alignSelf: "flex-start",
                maxWidth: "100%",
              }}>
                <FullTextTooltip text={newPost?.from}>
                  <Text
                    selectable={false}
                    numberOfLines={1}
                    style={[styles.user_name, { color: background.socialLogoColor }]}
                  >
                    {newPost?.from}
                  </Text>
                </FullTextTooltip>
              </View>
              {ableToShare && (
                <View style={styles.share_container}>
                  <Text
                    selectable={false}
                    style={{
                      color: color.socialShareWith,
                      fontSize: widthS * 8,
                      writingDirection: direction,
                      fontFamily: "Catamaran_600SemiBold",
                    }}
                  >
                    {t("share_with")}:
                  </Text>
                  <View style={styles.share_view}>
                    <SearchInput
                      styleType={{ dark: false, social: true, type: "share", check: true }}
                      value={newPost}
                      setValue={(post) => { setNewPost({ ...newPost, share: post.share, share_ids: post.share_ids }); }}
                      //system={type !== "mail" && playerPermission !== "1"}
                      err={errMsg}
                      styles={styles}
                    />
                  </View>
                </View>
              )}
            </View>
          </View>
          <View style={[styles.body, errMsg && !newPost.post_text.length > 0 && styles.mail_error]}>
            {/* <TextInput
            ref={postRef}
            selectTextOnFocus={false}
            nativeID="social-popup"
            showSoftInputOnFocus={false}
            placeholderTextColor={"#989898"}
            placeholder={t("social-media_q")}
            multiline={true}
            // numberOfLines={postRows}
            // onContentSizeChange={(event) => {
            //   if (event.nativeEvent.contentSize.height > inputHeight){
            //     setPostRows(prev => prev + 1);
            //     setInputHeight(event.nativeEvent.contentSize.height);
            //   }
            // }}

            style={[
              styles.post_text,
              {
                fontSize: inputfontSize,
                height: inputHeight,
                maxHeight: heightS * 140,
                minHeight: heightS * 35,
                resize: "none",
                overflow: inputHeight >= heightS * 140 ? "visible" : "hidden",
              },
            ]}
            value={newPost.post_text ? newPost.post_text : ""}
            onChangeText={(txt) => changePostHandler(txt)}
          /> */}
            <View style={styles.post_text}>
              <EditorTiny
                placeholder={t("social-media_q")}
                id={"social-popup"}
                refCurrent={postRef}
                setText={(txt) => changePostHandler(txt)}
                textCurrent={newPost.post_text ? newPost.post_text : ""}
              />
            </View>
            {/* {newPost.file && <View style={[styles.view_image]}>
							<Pressable
								style={[styles.btn_image, { backgroundColor: background.popup1 }]}
								onPress={() => setNewPost({ ...newPost, file: "" })}
							>
								<Text style={{ color: "white" }}>X</Text>
							</Pressable>
							{
								newPost.file.mimeType.indexOf("image") >= 0
									? <Image source={{ uri: newPost.file.uri }} style={styles.image} /> :
									<video style={{ width: widthS * 200, height: heightS * 100, borderRadius: widthS * 8, objectFit: "fill" }} controls>
										<source src={newPost.file.uri} />
									</video>
							}
						</View>} */}
            {newPost.file && (
              <Pressable
                onPress={() =>
                  newPost.file.mimeType.indexOf("image") >= 0 &&
                  dispatch(
                    showFile({
                      name: newPost.file.file.name,
                      type: newPost.file.file.type,
                      link: newPost.file.uri,
                    })
                  )
                }
                style={[styles.view_image]}
              >
                <Pressable
                  style={[
                    styles.btn_image,
                    { backgroundColor: background.popup1 },
                  ]}
                  onPress={() => setNewPost({ ...newPost, file: "" })}
                >
                  <Text style={{ color: "white" }}>X</Text>
                </Pressable>
                {newPost.file.mimeType.indexOf("image") >= 0 ? (
                  <Image
                    source={{ uri: newPost.file.uri }}
                    resizeMode="cover"
                    style={[
                      styles.image,
                      {
                        height: Math.min(mainWidth * imageRatio, heightS * 500),
                      },
                    ]}
                  />
                ) : (
                  <video
                    style={{
                      width: "100%",
                      maxHeight: heightS * 500,
                      objectFit: "fill",
                    }}
                    controls
                  >
                    <source src={newPost.file.uri} />
                  </video>
                )}
              </Pressable>
            )}
          </View>
          <View
            style={[
              styles.footer,
              {
                borderTopColor: color.socialPostBorder,
                borderTopWidth: newPost.file ? 0 : 1,
                paddingVertical: newPost.file ? heightS * 7 : heightS * 5,
              },
            ]}
          >
            <View
              style={[
                styles.files_view,
                {
                  opacity: newPost.file ? 0 : 1,
                },
              ]}
            >
              <Pressable
                ref={photoRef}
                style={[styles.footer_btn]}
                onPress={() => openImagesBrowser()}
                onHoverIn={() => { setShowFileLimit({ ...showFileLimit, photo: true }) }}
                onHoverOut={() => { setShowFileLimit({ ...showFileLimit, photo: false }) }}
              >
                <FontAwesome
                  selectable={false}
                  name="photo"
                  size={widthS * 8}
                  color={color.socialNewPostIcons}
                  style={{ marginRight: widthS * 2 }}
                />
                <Text
                  selectable={false}
                  style={[
                    styles.option_footer_text,
                    { color: color.socialNewPostIcons },
                  ]}
                >
                  {t("photo")}
                </Text>
                {showFileLimit.photo && (
                  <FileLimitPopup
                    refObj={photoRef}
                  />
                )}
              </Pressable>
              <Pressable
                ref={videoRef}
                style={styles.footer_btn}
                onPress={() => openVideosBrowser()}
                onHoverIn={() => { setShowFileLimit({ ...showFileLimit, video: true }) }}
                onHoverOut={() => { setShowFileLimit({ ...showFileLimit, video: false }) }}
              >
                <Ionicons
                  selectable={false}
                  name="md-play-circle-outline"
                  size={widthS * 8}
                  color={color.socialNewPostIcons}
                />
                <Text
                  selectable={false}
                  style={[
                    styles.option_footer_text,
                    { color: color.socialNewPostIcons },
                  ]}
                >
                  {t("video")}
                </Text>
                {showFileLimit.video && (
                  <FileLimitPopup
                    refObj={photoRef}
                  />
                )}
              </Pressable>
            </View>
            <Pressable
              //disabled={!newPost.from_id || newPost.post_text.trim().length === 0}
              style={[
                styles.new_btn,
                {
                  backgroundColor:
                    !newPost.from_id || newPost.post_text.trim().length === 0
                      ? background.socialPostDisabled
                      : background.socialLogoColor,
                },
              ]}
              onPress={() => checkEnd(() => addPost())}
            >
              <Text selectable={false} style={styles.new_btn_text}>
                {t("social-media_post")}
              </Text>
            </Pressable>
          </View>
        </View>
      </View>
    </>

  );
}
const stylesR = (width, height, heightS, widthS, background, direction) => {
  return StyleSheet.create({
    container: (flag) => ({
      paddingHorizontal: widthS * 30,
      zIndex: 1003,
      backgroundColor: flag ? background.infoBody : "",
      writingDirection: direction,
      borderBottomWidth: widthS * 0.8,
    }),
    title: {
      marginTop: "0.5vw",
    },
    main: (flag) => ({
      marginBottom: heightS * 13,
      borderRadius: widthS * 11,
      display: "flex",
      flexDirection: "column",
      // marginTop: !flag ? heightS * 13 : 0,
      alignItems: "center",
    }),
    top: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignSelf: "flex-start",
      zIndex: 4,
      paddingHorizontal: widthS * 10,
      columnGap: "0.5vw",
      maxWidth: "100%"
    },
    user_img: {
      width: widthS * 25,
      height: widthS * 25,
      borderRadius: "50%",
      resizeMode: "cover",
      margin: widthS * 4,
    },
    top_right: {
      // marginLeft: widthS * 3
      display: "flex",
      flexDirection: "column",
      writingDirection: direction,
      alignItems: direction === "rtl" ? "flex-end" : "flex-start",
      justifyContent: "center",
      flex: 1
    },
    user_name: {
      fontSize: widthS * 10,
      fontFamily: "Catamaran_800ExtraBold",
      lineHeight: heightS * 10,
      writingDirection: direction,
      maxWidth: "100%"
    },
    share_container: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginTop: heightS * 3,
      columnGap: widthS * 3,
      height: widthS * 18,
      marginLeft: widthS * 0.3,
    },
    list_share: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: widthS * 95,
      borderRadius: widthS * 5,
      height: heightS * 18,
      justifyContent: "space-between",
    },
    text_share: {
      fontSize: widthS * 8,
      fontFamily: "Catamaran_400Regular",
    },
    arrow: {
      marginHorizontal: widthS * 6,
      position: "absolute"
    },
    body: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      marginLeft: widthS * 5,
      marginRight: widthS * 5,
      width: "100%",
      marginTop: heightS * 8,
      zIndex: 3,
    },
    post_text_view: {
      // borderWidth: 1,
      borderRadius: widthS * 13,
      paddingHorizontal: widthS * 5,
      minHeight: heightS * 23,
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    post_text: {
      borderWidth: 0,
      width: "100%",
      marginVertical: heightS * 5,
      outlineStyle: "none",
      fontFamily: "Catamaran_400Regular",
    },
    view_image: {
      position: "relative",
      width: "100%",
    },
    image: {
      zIndex: 2,
      width: "100%",
    },
    btn_image: {
      position: "absolute",
      right: widthS * 10,
      top: heightS * 10,
      borderRadius: "50%",
      width: widthS * 11,
      height: widthS * 11,
      alignItems: "center",
      justifyContent: "center",
      zIndex: 3,
    },
    new_btn: {
      // width: widthS * 50,
      // height: heightS * 23,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: widthS * 4,
      paddingHorizontal: widthS * 8,
      // paddingVertical: heightS * 10,
    },
    new_btn_text: {
      color: "white",
      fontFamily: "Catamaran_600SemiBold",
      fontSize: widthS * 8,
    },
    footer: {
      paddingHorizontal: widthS * 8,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "93%",
      marginHorizontal: "auto",
    },
    footer_btn: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      columnGap: widthS * 2,
    },
    option_footer_text: {
      fontFamily: "Catamaran_600SemiBold",
      fontSize: widthS * 9,
    },
    files_view: {
      flexDirection: "row",
      flex: 0.4,
      justifyContent: "space-between",
    },
    share_view: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: widthS * 100,
      borderRadius: widthS * 5,
      height: heightS * 18,
    },
    search_bar_pressable: {
      flex: 1,
      borderRadius: "10px",
      //display: "flex",
      //flexDirection: "row",
      //alignItems: "center",
      //width: widthS * 95,
      borderRadius: widthS * 5,
      //height: heightS * 18,
      //justifyContent: "space-between",
    },
    _btn: {
      borderRadius: widthS * 5,
      display: "flex",
      flexDirection: "column",
      zIndex: 3,
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
    },
    _btn_head: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      minHeight: heightS * 15,
      alignItems: "center",
    },
    label_text_input: {
      fontSize: widthS * 9,
      fontFamily: "Catamaran_400Regular",
      maxWidth: "96%",
      lineHeight: heightS * 12,
      maxHeight: heightS * 15,
      marginLeft: widthS * 3,
      display: "flex",
      alignItems: "center",
    },
    _btn_open: {
      borderTopLeftRadius: widthS * 5,
      borderTopRightRadius: widthS * 5,
      display: "flex",
      flexDirection: "column",
      zIndex: 3,
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
    },
    shadow: {
      shadowColor: "black",
      shadowOffset: { width: 0, height: 0 },
      shadowOpacity: 0.8,
      shadowRadius: 3,
    },
    list_item: {
      zIndex: 13,
      width: "100%",
      width: widthS * 95,
    },
    border_circle: {
      borderRadius: widthS * 25,
      //marginHorizontal: widthS * 3,
      marginLeft: widthS * 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    list_item_text: {
      zIndex: 13,
      margin: widthS * 3,
      marginLeft: widthS * 7,
      fontSize: widthS * 8,
      fontFamily: "Catamaran_600SemiBold",
      display: "flex",
      alignItems: "center",
    },
    _btn_body: {
      width: "100%",
      borderBottomLeftRadius: widthS * 5,
      borderBottomRightRadius: widthS * 5,
      borderTopWidth: 1,
      shadowColor: "black",
      shadowOffset: { width: 0, height: 1 },
      shadowOpacity: 0.2,
      shadowRadius: 3,
      elevation: 10,
    },
    popup_list_content: {
      // backgroundColor: "#ebebeb",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      borderRadius: widthS * 9,
    },
    popup_list: {
      maxHeight: "20vh",
      minHeight: "8vh",
      zIndex: 100,
    },
    mail_error: {
      borderColor: "red",
      borderWidth: "2px",
      borderRadius: widthS * 5,
    },
    des_container: {
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      width: "3vw",
      height: "4vh",
      maxHeight: "7vh",
      zIndex: 15,
    },
    traingular: {
      width: 0,
      height: 0,
      borderLeftWidth: widthS * 8,
      borderRightWidth: widthS * 8,
      borderBottomWidth: widthS * 8,
      borderStyle: "solid",
      backgroundColor: "transparent",
      borderLeftColor: "transparent",
      borderRightColor: "transparent",
      zIndex: 4,
      marginLeft: widthS * 22,
    },
    des_view: {
      marginTop: height - 2,
      width: "10vw",
      padding: "1vw",
      borderRadius: widthS * 3,
      minHeight: "4vh",
      maxHeight: "7vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 11,
    },
    des_text: {
      textAlign: "left",
      fontSize: widthS * 7,
      width: "90%",
      fontFamily: "Catamaran_400Regular",
    },
  });
};
