import React, { useContext, useEffect, useMemo, useState } from "react";
import { StyleSheet, ScrollView, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import NewPost from "./NewPost";
import Post from "./Post";
import { SocialContext } from "../../../ContextData/Social";
import SocialCreatorList from "./SocialCreatorList";
import TextCustomize from "../../Elements/TextCustomize";
import { CREATE_GAME_PERMISSION } from "../../../config.inc";
import { loading } from "../../../redux/actions";
import FullTextTooltip from "../../Elements/FullTextTooltip";

export default function SocialBody({ posts }) {

  const userImage = useSelector(
    (state) => state.user.user.profile_image
  );

  const { addNewPost } = useContext(SocialContext);
  const dispatch = useDispatch();

  const [count, setCount] = useState(50);

  const background = useSelector((state) => state.style.background);
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const styles = stylesR(background, direction);

  const playerPermission = useSelector((state) => state.gameInfoArr.permission);
  const repPlayersState = useSelector(state => state.players.repPlayers);
  const realPlayersState = useSelector(state => state.players.realPlayers);

  const ableToShare =
    sessionStorage.getItem(CREATE_GAME_PERMISSION) === "1" ||
    playerPermission === "1";
  // || playerPermission === "10";

  const [players, setPlayers] = useState([]);
  const [errMsg, setErrMsg] = useState(false);
  const [from, setFrom] = useState({ name: "", id: "" });
  const [resetPost, setResetPost] = useState(false);
  const [newPost, setNewPost] = useState({
    file: null,
    from: "",
    from_id: "",
    from_image: userImage,
    post: "",
    post_text: "",
    share_ids: "",
    share: "",
  });

  const addPost = () => {
    if (newPost.share_ids.length > 0) {
      if (newPost.post_text.trim().length > 0) {
        setErrMsg(false);
        dispatch(loading(true));
        addNewPost(newPost).then(() => {
          dispatch(loading(false));
          setNewPost({
            file: null,
            from: "",
            from_id: "",
            from_image: userImage,
            post: "",
            post_text: "",
            share_ids: "",
            share: "",
          });
          setResetPost(!resetPost);
        }).catch(() => {
          dispatch(loading(false));
        });
        return;
      }
      return setErrMsg(true);
    }
    setErrMsg(true);
  };

  useEffect(() => {
    if (ableToShare && players?.length > 0 && from?.id?.length === 0) {
      setFrom({ name: players[0].name_for_game, id: players[0].id });
    } else if (!ableToShare) {
      setFrom({ name: sessionStorage.getItem("user_game_name"), id: sessionStorage.getItem("id_in_game") });
    }
  }, [players, ableToShare]);

  useEffect(() => {
    setPlayers([...realPlayersState, ...repPlayersState]);
  }, [repPlayersState, realPlayersState]);

  useEffect(() => {
    if (ableToShare) {
      setNewPost({ ...newPost, from: from.name, from_id: from.id });
    }
  }, [from, newPost.from_id]);

  return (
    <View style={styles.container}>
      {ableToShare && <SocialCreatorList
        data={players}
        active={from.id}
        setActive={(id, name) => setFrom({ name: name, id: id })}
      />}
      <ScrollView
        scrollEventThrottle={50}
        style={styles.scroll}
        contentContainerStyle={styles.scroll_view}
        onScroll={(e) => {
          const bottom =
            e.nativeEvent.contentOffset.y >=
            e.nativeEvent.contentSize.height - 500;
          if (bottom) {
            setCount((prev) => prev + 50);
          }
        }}
        nativeID="social"
      >
        {ableToShare && <View style={styles.noteView}>
          <TextCustomize fontWeight={600} style={{ whiteSpace: "normal" }} keyText={"social_creator_note"} />
          <FullTextTooltip style={{ flex: 1 }} text={newPost?.from} >
            <TextCustomize fontWeight={600} style={{ whiteSpace: "pre", flex: 1 }} text={newPost?.from} numberOfLines={1} />
          </FullTextTooltip>
        </View>}
        {playerPermission !== "17" && playerPermission !== "10" && (
          <NewPost
            newPost={newPost}
            setNewPost={setNewPost}
            addPost={addPost}
            errMsg={errMsg}
            resetPost={resetPost}
          />
        )}
        <TextCustomize style={styles.title} fontWeight={600} keyText={"social_feed_title"} />
        {posts.slice(0, count).map((post, index) => (
          <Post data={post} key={post?.id} zIndex={(posts?.length - 1) - index} from={from?.id} />
        ))}
      </ScrollView>
    </View>
  );
}
const stylesR = (background, direction) => {
  return StyleSheet.create({
    container: {
      flexDirection: "row",
      maxHeight: "100%",
      height: "100%",
    },
    scroll: {
      width: "100%",
    },
    noteView: {
      flex: 1,
      height: "3vw",
      backgroundColor: background.loginFooter,
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      writingDirection: direction,
      position: "sticky",
      top: 0,
      zIndex: 1004,
      gap: "0.5vw",
      paddingHorizontal: "1em"
    },
    title: {
      marginHorizontal: "3vw",
      marginTop: "1vw"
    }
  });
};
