import React, { useState, useEffect, useRef, useMemo, useContext, createContext, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setChatFlag, setInGame, setToken } from "./redux/actions/index";
import { _F_PHPSESSID_, F_PHPSESSID, GAME_ID } from "./config.inc";
import { URL_PATH, NOTIFICATION_EMAIL } from "@env";
import { SocketManager } from "./utils/SocketManager";
import { setStatusPlayers } from "./redux/actions/creatorActions";
import { UserContext } from "./ContextData/User";
import { setErrorMessage } from './redux/actions';

export const WebSocketContext = createContext();

export default function WebSocketProvider(props) {

	const gameInfo = useSelector(state => state.gameInfoArr.gameInfoArr);
	const gameInfoStart = useSelector(state => state.gameInfoArr.gameInfoArr.start_time);
	const gameInfoPermission = useSelector(state => state.gameInfoArr.permission);
	/// Inactivity
	const timerId = useRef(false);
	const connecting = useRef(false);
	const { checkSession } = useContext(UserContext);
	const [timeForInactivityInSecond] = useState(900000);
	const dispatch = useDispatch();
	const [typeRefresh, setTypeRefresh] = useState(""); // for type data to refresh
	const [gameId, setGameId] = useState(() => window.sessionStorage.getItem(GAME_ID));
	const [finishedRound, setFinishedRound] = useState(false);
	const [reconnectAttempts, setReconnectAttempts] = useState(0);
	// const [numRounds, setNumRounds] = useState(5);
	const [reconnectStage, setReconnectStage] = useState(1);
	const [webSocketBlocked, setWebSocketBlocked] = useState(false);
	const [isInRoom, setIsInRoom] = useState(false);
	const [flag, setFlag] = useState(false);

	const waitingForPong = useRef(false);
	// const reconnectAttemptsRef = useRef(1);
	const reconnectStageRef = useRef(1);
	const intervalId = useRef(null);
	const manager = useRef(null);
	const stageTimer = useRef(null);

	const resetInactivityTimeout = () => {
		clearTimeout(timerId.current)
		timerId.current = setTimeout(() => {
			checkSession().then((res) => {
				if (!res) {
					sessionStorage.clear()
					window.location.reload(false);
				} else {
					resetInactivityTimeout();
				}
			})
		}, timeForInactivityInSecond)
	}

	const getUserDetails = () => ({
		id: window.sessionStorage.getItem("id_in_game") || "default",
		name: window.sessionStorage.getItem("user_game_name") || "default_name",
		room: gameId || window.sessionStorage.getItem("game_id"),
	});

	const joinRoom = (type) => {
		if (isInRoom) return;
		setIsInRoom(true);
		const userDetails = getUserDetails();
		const socket = manager.current.getSocketInstance();
		connecting.current = true;
		socket.emit('join-room', JSON.stringify(userDetails));
		console.log(`Joining room: ${userDetails.room} with ID ${userDetails.id}`);
	};

	const sentMessageWebSocket = (type) => {
		try {
			const { room } = getUserDetails();

			const socket = manager.current.getSocketInstance();
			socket.emit("refresh", JSON.stringify({ type, room }));

			console.log(`Sending message to room ${room} with type ${type}`);
		} catch (e) {
			console.log(`Sending message failed: ${e}`);
		}
	};

	// const refreshConnection = () => {
	// 	waitingForPong.current = false;
	// 	console.log("Calling refresh");
	// 	setFinishedRound(true);
	// 	if (reconnectStageRef.current === 2) {
	// 		setWebSocketBlocked(true);
	// 	}
	// 	// notifySocketIssue();
	// 	// dispatch(
	// 	// 	setErrorMessage(
	// 	// 		true,
	// 	// 		t("websocket_error_title"),
	// 	// 		t("websocket_error_body"),
	// 	// 		null,
	// 	// 		refreshNetwork,))
	// }

	// const notifySocketIssue = () => {
	// 	const url = `${URL_PATH}${NOTIFICATION_EMAIL}`;
	// 	const body = new FormData();
	// 	body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
	// 	body.append("gameId", gameId);
	// 	body.append("message", "Web-socket is having connection issue, please check server.");
	// 	try {
	// 		fetch(url, {
	// 			method: "POST",
	// 			body: body,
	// 		})
	// 			.then((res) => res.text())
	// 			.then((res) => {
	// 				///in the future we will add some logs
	// 			})
	// 	} catch (e) {
	// 		console.log(e)
	// 	}
	// }

	// const refreshNetwork = () => {
	// 	window.location.reload();
	// }


	const testWebSocket = () => {
		console.log("testWebSocket called");

		// waitingForPong.current = true;
		// const socket = manager.current.getSocketInstance();
		// socket.send("ping");
		clearInterval(intervalId.current);
		intervalId.current = setInterval(() => {
			if (waitingForPong.current) {
				// setReconnectAttempts(prev => prev + 1);
				// if (reconnectAttempts >= 5) {
				// 	connecting.current = false;
				// 	setWebSocketBlocked(true);
				// 	clearInterval(intervalId.current);
				// } else {
				// 	reconnectWebSocket();
				// }
				manager.current?.disconnectSocket();
			} else {
				waitingForPong.current = true;
				const socket = manager.current.getSocketInstance();
				socket.send("ping");
			}
		}, 30000); // Every 30 seconds
	};

	// to main as props
	const closeWebSocket = () => {
		manager.current.disconnectSocket();
		console.log("Socket disconnected!")
		console.log("Removed completed")
	};

	const leaveRoom = () => {
		setIsInRoom(false);
		connecting.current = false;
		setGameId(null);
		clearInterval(intervalId.current);

		const socket = manager.current?.getSocketInstance();
		if (socket) {
			// שליחת בקשה ליציאה מהחדר (אם רלוונטי)
			if (window.sessionStorage.getItem("id_in_game") != null && window.sessionStorage.getItem(GAME_ID) != null) {
				socket.emit("leave", JSON.stringify({
					user_id: window.sessionStorage.getItem("id_in_game"),
					room: window.sessionStorage.getItem(GAME_ID)
				}));
			}

			// הסרת כל המאזינים
			socket.removeAllListeners();

			// ניתוק החיבור
			socket.disconnect();
			console.log("Socket disconnected and listeners removed");
			manager.current = null; // איפוס ה-manager
		}
	};

	const reconnectWebSocket = (flag) => {
		setReconnectAttempts(prev => {
			const newAttempts = prev + 1; // מחשב את מספר הניסיונות החדש
			if (newAttempts >= 5) {
				connecting.current = false;
				setIsInRoom(false);
				setWebSocketBlocked(true);
				return newAttempts; // מחזיר את המספר החדש של הניסיונות
			} else {
				setTimeout(() => {
					connecting.current = false;
					waitingForPong.current = false;
					clearInterval(intervalId.current);
					setIsInRoom(false);
					console.log('Attempting to reconnect...');
					if (manager.current) {
						manager.current?.disconnectSocket();
						manager.current?.reconnectSocket();
						const socket = manager.current?.getSocketInstance(); // ודא שאתה לוקח את ה-socket הנכון
						attachSocketListeners(socket); // הוספת המאזינים מחדש
					}
				}, 2000);
			}
			return newAttempts; // מחזיר את המספר החדש של הניסיונות
		});
	};

	const handlePong = () => {
		console.log("Pong received, connection is alive.");
		waitingForPong.current = false;
		setReconnectAttempts(0);
		setWebSocketBlocked(false);
	};

	const pullRouter = (e) => {
		console.log(`In pullRouter: ${e}`);
		setTypeRefresh(e);
	};

	const updateUsersOnline = (e) => {
		console.log("Updating users online:", e);
		dispatch(setStatusPlayers([e]));
	};

	// const reconnectSocket = () => {
	// 	waitingForPong.current = false;
	// 	console.log('Attempting to reconnect...');
	// 	connecting.current = false;
	// 	setIsInRoom(false);
	// 	manager.current.disconnectSocket();
	// 	manager.current.reconnectSocket();
	// 	const socket = manager.current?.socket;
	// 	attachSocketListeners(socket);
	// 	clearInterval(intervalId.current);
	// };

	// useEffect(() => {
	// 	console.log("Waiting for pong was change to -  ", waitingForPong.current)
	// 	return;
	// }, [waitingForPong.current]);

	useEffect(() => {
		if (reconnectStage === 1 && reconnectAttempts >= 5 && !connecting.current && gameId) {
			console.log('Transitioning to stage 2'); // הוספת לוג
			setReconnectStage(2);
			setReconnectAttempts(1);
		}
	}, [reconnectAttempts, reconnectStage, connecting.current, gameId]);

	useEffect(() => {
		if (reconnectStage === 2 && gameId && (!connecting.current || !isInRoom)) {
			clearInterval(stageTimer.current);
			stageTimer.current = setInterval(() => {
				console.log('Calling testWebSocket from stage 2 evry minutes'); // לוג נוסף
				reconnectWebSocket();
			}, 60000); // 20 שניות
		} else if (!gameId || connecting.current || isInRoom) {
			clearInterval(stageTimer.current);
		}
	}, [reconnectStage, gameId, isInRoom, connecting.current])

	const attachSocketListeners = (socket) => {
		console.log("Attaching socket listeners"); // לוג כדי לוודא שהפונקציה נקראת
		socket.on('connect', joinRoom);
		socket.on('error', (error) => { console.error(error); });
		socket.on('connect_error', (error) => { console.error(error); reconnectWebSocket(); });
		socket.on('reconnect', joinRoom);
		socket.on('disconnect', () => { console.log('Disconnect from socket'); reconnectWebSocket(); });
		socket.on('connect_timeout', () => console.log('connect_timeout from socket'));
		socket.on('reconnect_failed', () => console.log('reconnect_failed from socket'));
		// socket.on('connection-error', () => { reconnectWebSocket() });
		socket.on('users', updateUsersOnline);
		socket.on('response', pullRouter);
		socket.on('pong', handlePong);
	};

	useEffect(() => {
		const newGameId = sessionStorage.getItem(GAME_ID); // קח את הערך החדש

		if (newGameId && newGameId !== gameId) {
			setGameId(newGameId); // עדכן את gameId אם הוא קיים
			setFlag(!flag);
			setWebSocketBlocked(false);
			setReconnectAttempts(0);
			setReconnectStage(1);
		} else {
			manager.current = null;
			setGameId(null);
		}
	}, [sessionStorage.getItem(GAME_ID)]);

	const func = useCallback(() => {
		if (gameId !== null && !webSocketBlocked && !isInRoom && !connecting.current) {
			SocketManager.resetInstance();
			manager.current = new SocketManager();
			const socket = manager.current.getSocketInstance();
			console.log("func");

			attachSocketListeners(socket);

			if (window.sessionStorage.getItem('F_PHPSESSID') && window.sessionStorage.getItem('GAME_ID')) {
				dispatch(setToken(true));
				dispatch(setInGame());
			}
		}
		// else if (gameId !== null && !webSocketBlocked && !isInRoom && connecting.current) {
		// 	// manager.current = new SocketManager();
		// 	const socket = manager.current.getSocketInstance();
		// 	attachSocketListeners(socket);
		// 	manager.current?.reconnectSocket();
		// }
		return () => {
			socket.off();
		};
	}, [gameId, webSocketBlocked, isInRoom, connecting.current]);

	useEffect(() => {
		func();
	}, [gameId, flag, webSocketBlocked]);

	useEffect(() => {
		let myTimeOut;
		if (isInRoom) {
			myTimeOut = setTimeout(() => {
				clearInterval(intervalId.current);
				testWebSocket();
			}, 3000);
		}
		return () => {
			clearTimeout(myTimeOut);
			clearInterval(intervalId.current);
		}
	}, [isInRoom]);

	// useEffect(() => {
	// 	if (!connecting.current || !isInRoom) {
	// 		handlePong();
	// 	}
	// }, [connecting.current, isInRoom, handlePong]);

	return (
		<WebSocketContext.Provider
			value={{
				sentMessageWebSocket,
				typeRefresh,
				leaveRoom,
				closeWebSocket,
				setTypeRefresh,
				finishedRound,
				isInRoom,
				webSocketBlocked,
				connecting: connecting.current
			}}
		>
			{props.children}
		</WebSocketContext.Provider>
	);
}