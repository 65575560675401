import { Pressable, StyleSheet, Text, View } from 'react-native'
import React, { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import UseResponsiveSize from '../../../modules/UseResponsiveSize';
import { loading } from "../../../../redux/actions";
import UseResponsiveScreen from '../../../modules/UseResponsiveScreen';
import { t } from 'i18n-js';
import { Ionicons, AntDesign, MaterialIcons } from '@expo/vector-icons';
import { useContext } from 'react';
import { HeatMapFuncContext } from './analyticsFunction/HeatMapFunc';
import { exportExcel } from './ExportExcel';
import { createPng, createFromSvgPng } from '../../../modules/downloadPNG';
import { listenerAdd } from '../../../../AddListener';
import TextCustomize from '../../../Elements/TextCustomize';
import { Tooltip as ReactTooltip } from 'react-tooltip';

export default function ExportPopup({ num, type, id, callback, nativeID, inline, statisticsRef, heatMapRef, interactionRef, wordCloudRef, allQuestionnaireRef, questionnaireRef, setIsShot, isShot, disabled }) {
    const { width, height } = UseResponsiveSize();
    const { widthS, heightS } = UseResponsiveScreen();

    const dispatch = useDispatch();

    const btnRef = useRef(null);

    const { excelJson, excelJsonReal } = useContext(HeatMapFuncContext);

    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);
    const styles = stylesR(width, height, heightS, widthS, background, color);

    const images = useSelector((state) => state.style.images);

    let mapTypesStatic = [
        { type: "statistics", name: `${t("statistics_map")}`, types: ["PNG"], id: "statistics", ref: statisticsRef },
        { type: "heat", name: t("HEAT"), types: ["PNG", "XLS", "XLSX"], comparedTypes: ["PNG", `XLS - ${t("real")}`, `XLSX - ${t("real")}`, `XLS - ${t("expected")}`, `XLSX - ${t("expected")}`], id: "heatMap", ref: heatMapRef },
        { type: "interaction", name: t("INTERACTION"), types: ["PNG"], id: "interactionMap", ref: interactionRef },
        { type: "words", name: t("word_map"), types: ["PNG"], id: "wordsMap", ref: wordCloudRef },
        { type: "all-questionnaire", name: t("questionaire"), types: ["PNG"], id: "all-questionnaire", ref: allQuestionnaireRef },
        { type: "questionnaire", name: t("questionaire"), types: ["PNG"], id: "questionnaire", ref: questionnaireRef },
    ];

    const [exportHeatMapType, setExportHeatMapType] = useState();
    const [showPopup, setShowPopup] = useState(false);
    const [isPressed, setIsPressed] = useState(false);
    const [currentId, setCurrentId] = useState(false);

    const handlePressIn = () => {
        setIsPressed(true);
        setIsShot(mapTypesStatic[num - 1].type)
    };

    const handlePressOut = () => {
        setIsPressed(false);
    };

    const thenAndCatch = (promise, index) => {
        promise.then(() => {
            setIsPressed(false);
            dispatch(loading(false));
            setExportHeatMapType(false);
        }).catch(() => {
            setIsPressed(false);
            dispatch(loading(false));
            setExportHeatMapType(false);
        })
    }

    const dropDown = (typesFilesArr, typeMap, exportType, setExportType) => {
        return (
            <View ref={btnRef} style={styles.popupTypes}>
                <View style={[styles.btnItemPopuop]}>
                    <Text style={styles.text}>{exportType || t("choose_type")}</Text>
                    <AntDesign
                        name="caretdown"
                        style={{ marginLeft: "0.5vw" }}
                        size={"0.9vw"}
                        color={color.text2}
                    />
                </View>
                {typesFilesArr.map((type, index) => {
                    if (exportType === type) return null;
                    const isLastItem = index === typesFilesArr.length - 1;
                    return (
                        <Pressable
                            key={index}
                            onPress={() => {
                                if (index === 0) {
                                    setIsShot(typeMap);
                                } else {
                                    downloadHeatMapFunc(type);
                                }
                                setExportType(type);
                                setShowPopup(false);
                            }}
                            style={({ hovered }) => [
                                styles.btnItemPopuop,
                                hovered && { backgroundColor: background.infoBody, },
                                hovered && isLastItem ? styles.lastItemHovered : {},
                            ]}
                        >
                            <Text style={styles.text}>{type}</Text>
                        </Pressable>
                    );
                })}
            </View>
        )
    }

    const createDivForShot = (refCurrent, width) => {
        const div = refCurrent;
        div.style.flex = "none";
        div.style.alignSelf = "center";
        div.style.minWidth = "100%";
        div.style.height = "";
        let divNew = document.createElement("div");
        divNew.id = "duplicate";
        divNew.style.position = "absolute";
        divNew.style.width = width ? "100%" : "max-content";
        divNew.style.minHeight = "100vh";
        divNew.style.zIndex = -1000;
        divNew.style.display = "flex";
        divNew.appendChild(div);
        document.body.appendChild(divNew);
    }

    // const leftPopupMapNames = (
    //     mapTypes.map((name, index) => {
    //         if (type === "expected" && name.type === "questionnaire") return;
    //         return (
    //             <View style={[styles.line, index < mapTypes.length - 1 && styles.borderBottom, { zIndex: mapTypes.length - (index + 1) }]} key={index}>
    //                 <View style={styles.left}>
    //                     <Text key={index} style={styles.text}>{name.name}</Text>
    //                 </View>
    //                 <View style={styles.center}>
    //                     {(() => {
    //                         if (name.types.length > 1) {
    //                             return dropDown(type === "compared" && name.type === "heat" ? name.comparedTypes : name.types, name.type, exportHeatMapType, setExportHeatMapType);
    //                         }
    //                         return <Text key={index} style={styles.text}>{name.types[0]}</Text>
    //                     })()}
    //                 </View>
    //                 <View style={styles.right}>
    //                     <Pressable style={styles.btn} key={index}

    //                     >
    //                         <Ionicons name="md-cloud-download" style={{ fontSize: "1.3vw" }} color={color.infoHeaderNavText} />
    //                     </Pressable>
    //                     {name.download}
    //                 </View>
    //             </View>
    //         )
    //     })
    // );

    const onPress = async (num) => {
        downloadFunc(mapTypesStatic[num - 1]);
    }

    const downloadFunc = async (name) => {
        dispatch(loading(true));
        if ((name.type === "heat" && exportHeatMapType === "PNG") || (name.type !== "heat")) {
            // await setIsShot(name.type);
            document.body.style.overflow = "hidden";
            let divClone;
            let div;
            if (name.type !== "interaction" && name.type !== "words" && (name.type !== "questionnaire" && name.type !== "all-questionnaire")) {
                if (name.ref.current?.length > 1) {
                    div = document.createElement("div");
                    div.style.display = "flex";
                    div.style.flexDirection = "column";
                    div.style.alignItems = "center";
                    div.style.gap = "2vw";
                    div.style.justifyContent = "center";
                    div.appendChild(name.ref.current[0].cloneNode(true));
                    div.appendChild(name.ref.current[1].cloneNode(true));
                    createDivForShot(div.cloneNode(true));
                    divClone = document.getElementById("duplicate");
                    setIsShot(false);
                }
                else {
                    createDivForShot(name.ref.current[0].cloneNode(true));
                    divClone = document.getElementById("duplicate");
                    setIsShot(false);
                }
            } else {
                if (name.type === "questionnaire") {
                    if (currentId === id) {
                        divClone = document.getElementById(id);
                    }
                } else {
                    divClone = document.getElementById(name.id);
                }
            }
            setTimeout(() => {
                createPng(divClone, `statisticts${new Date().toDateString()}`)
                    .then(() => {
                        if (divClone && document.body.contains(divClone)) {
                            if (name.type !== "interaction" && name.type !== "words" && (name.type !== "questionnaire" && name.type !== "all-questionnaire")) {
                                document.body.removeChild(divClone);
                                document.body.style.overflow = "";

                            }
                        }
                        setIsShot(false);
                        setCurrentId(false);
                        dispatch(loading(false));
                        setExportHeatMapType(false);
                        setIsPressed(false);
                    })
                    .catch(() => {
                        if (divClone && document.body.contains(divClone)) {
                            if (name.type !== "interaction" && name.type !== "words" && (name.type !== "questionnaire" && name.type !== "all-questionnaire")) {
                                document.body.removeChild(divClone);
                                document.body.style.overflow = "";
                            }
                        }
                        setIsShot(false);
                        dispatch(loading(false));
                        setExportHeatMapType(false);
                        setIsPressed(false);
                        setCurrentId(false);
                    });
            }, 1000);
        }
    }

    const downloadHeatMapFunc = (exportHeatMapType) => {
        dispatch(loading(true));
        if (type === "expected" || (exportHeatMapType === `XLS - ${t("expected")}` || exportHeatMapType === `XLSX - ${t("expected")}`)) {
            thenAndCatch(exportExcel(excelJson, "heatMapExpected"), num - 1);
        } else if (type === "real" || (exportHeatMapType === `XLS - ${t("real")}` || exportHeatMapType === `XLSX - ${t("real")}`)) {
            thenAndCatch(exportExcel(excelJsonReal, "heatMapReal"), num - 1);
        }
    }

    useEffect(() => {
        if ((isShot === "questionnaire" && num === 6) || (isShot !== "questionnaire" && isShot)) {
            onPress(num);
        }
    }, [isShot, num, nativeID]);

    useEffect(() => {
        window.addEventListener("mousedown", listenerAdd(btnRef, showPopup, () => { setShowPopup(false); setIsPressed(false) }));
        return () => {
            window.removeEventListener("mousedown", listenerAdd(btnRef, showPopup, () => { setShowPopup(false); setIsPressed(false) }));
        }
    }, [btnRef.current, showPopup])

    return (
        <>
            {showPopup && dropDown(type === "compared" ? mapTypesStatic[1].comparedTypes : mapTypesStatic[1].types, "heat", exportHeatMapType, setExportHeatMapType)}
            <Pressable
                nativeID={nativeID || 'export'}
                disabled={disabled}
                style={({ pressed }) => ([styles.btnExport(isPressed, disabled, inline)])}
                onPress={async () => {
                    if (num === 2) {
                        setIsPressed(true);
                        return setShowPopup(true);
                    }
                    if (callback) {
                        setCurrentId(callback());
                    }
                    // if (num === 6) {
                    //     return onPress(num);
                    // }
                    await handlePressIn();

                }}
            >
                {isPressed && <TextCustomize fontSize={"1.2vw"} fontWeight={600} text={t("export").toUpperCase()} />}
                <AntDesign name="export" size={24} color={color.text2} />
            </Pressable>
            {!nativeID && <ReactTooltip
                anchorSelect={`#export`}
                content={<TextCustomize text={t("export").toUpperCase()} fontWeight={500} fontSize={"0.8vw"} />}
                style={{ fontFamily: "Catamaran_400Regular" }}
                place='top'
            />}
            {nativeID && <ReactTooltip
                anchorSelect={`#exportA`}
                content={<TextCustomize text={t("export").toUpperCase()} fontWeight={500} fontSize={"0.8vw"} />}
                style={{ fontFamily: "Catamaran_400Regular" }}
                place='top'
            />}
        </>

    )
}

const stylesR = (width, height, heightS, widthS, background, color) => {
    return StyleSheet.create({
        btnExport: (active, disabled, inline) => ({
            ...(!inline && {
                position: "absolute",
                top: "0.5vw",
                right: "1.5vw",
            }),
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.6vw",
            opacity: disabled ? 0.5 : 1,
            paddingHorizontal: "0.5vw",
            transition: "all 0.5s",
            ...(active && {
                borderRadius: "0.8vw",
                paddingHorizontal: "1.vw",
                backgroundColor: background.infoBox,
            })
        }),
        line: {
            flexDirection: "row",
            alignItems: "center",
            paddingVertical: "0.3vw"
        },
        borderBottom: {
            borderBottomWidth: "0.1vw",
            borderBottomColor: color.border
        },
        left: {
            flex: 1,
        },
        center: {
            flex: 1,
            alignItems: "center"
        },
        right: {
            flex: 1,
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "center"
        },
        text: {
            display: "flex",
            height: "1.5vw",
            marginVertical: "0.5vw",
            alignItems: "center",
            fontSize: "1vw",
            fontFamily: "Catamaran_500Medium",
            color: color.infoHeaderNavText
        },
        btn: {
            paddingHorizontal: "1vw",
            backgroundColor: color.buttonMenuLeft,
            borderRadius: "0.8vw",
            paddingVertical: "0.2vw",
            marginVertical: "0.3vw",
        },
        selectTypes: {
            flexDirection: "row",
            minWidth: "9vw",
            backgroundColor: background.infoBody,
            paddingHorizontal: "1vw",
            borderRadius: "0.8vw",
            alignItems: "center",
            justifyContent: "space-between",
        },
        selectTypesOpen: {
            flexDirection: "row",
            minWidth: "9vw",
            backgroundColor: background.infoBody,
            paddingHorizontal: "1vw",
            borderTopLeftRadius: "0.8vw",
            borderTopRightRadius: "0.8vw",
            alignItems: "center",
            justifyContent: "space-between",
        },
        popupTypes: {
            position: "absolute",
            top: "140%",
            width: "12vw",
            right: "0.5vw",
            alignItems: "center",
            backgroundColor: background.infoBox,
            borderRadius: "0.8vw",
            overflow: "hidden", // זה עוזר לשמור על הגבולות המעוגלים גם בעת הרחה
        },
        btnItemPopuop: {
            alignItems: "center",
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
        },
        lastItemHovered: {
            borderBottomLeftRadius: "0.8vw",
            borderBottomRightRadius: "0.8vw",
        }
    });
} 