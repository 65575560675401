import React, { useEffect, useRef, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  Modal,
  Image,
  Pressable,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { Pressable as PressableWeb } from "react-native-web";
import "./scroll.css";
import { useDispatch, useSelector } from "react-redux";
import { AntDesign, Entypo, FontAwesome, Ionicons } from '@expo/vector-icons';
import onClickEndGameCheck from "../../Elements/onClickEndGameCheck";
import { t } from "i18n-js";
import {
  removeType,
  setActionInfo,
  setTypeMinimize,
  showActionInfo,
  showNewAction,
} from "../../../redux/actions";
import UseResponsiveSize from "../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";
import { USER_GAME_NAME, USER_ID_IN_GAME, USER_ID_IN_GAME_OBSERVER } from "../../../config.inc";
import { findCreateName } from "../../modules/FindNameById";
import ShortenWord from "../../modules/ShortenWord";
import HeaderPopupButtons from "../../Elements/HeaderPopupButtons";
import FullTextTooltip from "../../Elements/FullTextTooltip";

export default function Actions({ flag, setFlag, setDrag }) {

  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS);
  const { checkEnd } = onClickEndGameCheck();

  const dispatch = useDispatch();
  const refPopup = useRef(null);
  const lang = useSelector((state) => state.setting.setting.lang);
  const data = useSelector((state) => state.actionsArr.actionsArr);
  const minimizePopups = useSelector((state) => state.minimize.minimize);

  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);
  const images = useSelector((state) => state.style.images);
  const events = useSelector((state) => state.events.events);
  const { permission: playerPermission, gameInfoArr } = useSelector((state) => state.gameInfoArr);

  const printDescription = (des) => {
    if (!des) return
    let span = document.createElement("span");
    span.innerHTML = des.replace(/<br\s*\/?>/gi, ' ');
    let text = span.textContent || span.innerText;
    if (text.length > 28) {
      return `${text.slice(0, 28)}...`;
    } else {
      return text;
    }
  };


  return (
    <>
      <View
        style={[
          styles.main,
          { backgroundColor: background.popup1, borderColor: color.border },
        ]}
        ref={refPopup}
      >
        <View style={{ width: "100%", height: "100%" }}>
          <TouchableOpacity style={{ cursor: "grabbing" }} onPressIn={() => setDrag(true)}>
            <View style={[styles.header, { borderBottomColor: color.border }]} >
              <View style={styles.headerLeft}>
                <View
                  style={[
                    styles.logo_view,
                    { backgroundColor: background.actionLogo },
                  ]}
                >
                  <Image source={{ uri: images.action }} style={styles.logo} />
                </View>
                <Text selectable={false} style={styles.textLogo}>
                  {t("menu_challenge")}
                </Text>
              </View>
              {playerPermission !== "17" && playerPermission !== "10" && <Pressable
                onPress={() => {
                  checkEnd(() => dispatch(showNewAction(true)))
                }}
                style={[
                  styles.new_btn,
                  { backgroundColor: background.actionButtonAdd },
                ]}
              >
                <Text selectable={false} style={styles.new_btn_text}>
                  + {t("new")}
                </Text>
              </Pressable>}
              <HeaderPopupButtons
                close={() => { setFlag(); dispatch(removeType("challenges")) }}
                minimize={() => dispatch(setTypeMinimize({ name: "challenges", type: 1, show: false }))}
              />
            </View>
          </TouchableOpacity>
          <ScrollView nativeID="action" style={styles.body}>
            <View
              style={[
                styles.table,
                { direction: lang === "he" ? "rtl" : "ltr" },
              ]}
            >
              <View style={styles.row_head}>
                <Text
                  selectable={false}
                  style={[styles.cell_head, { flex: 1 }]}
                >
                  {t("name")}
                </Text>
                <Text
                  selectable={false}
                  style={[[styles.cell_head], { flex: 2 }]}
                >
                  {t("description")}
                </Text>
                <Text
                  selectable={false}
                  style={[styles.cell_head, { flex: 0.8 }]}
                >
                  {t("time")}
                </Text>
                <Text
                  selectable={false}
                  style={[styles.cell_head, { flex: 1 }]}
                >
                  {t("event")}
                </Text>
                <Text
                  selectable={false}
                  style={[styles.cell_head, { flex: 1 }]}
                >
                  {t("shared")}
                </Text>
                <Text
                  selectable={false}
                  style={[styles.cell_head, { flex: 1 }]}
                >
                  {t("create_by")}
                </Text>
              </View>
              {data &&
                data.map((e, i) => {
                  return (
                    <Pressable
                      onPress={() => {
                        dispatch(setActionInfo({ ...e, index: i }));
                        dispatch(showActionInfo(true));
                      }}
                      key={i}
                    >
                      {({ pressed, hovered }) => (
                        <View
                          style={[
                            styles.row,
                            {
                              backgroundColor: hovered
                                ? background.actionHover
                                : "",
                            },
                          ]}
                        >
                          {/* name */}
                          <FullTextTooltip style={{ flex: 1.2, }} text={e.action.name}>
                            <Text
                              selectable={false}
                              numberOfLines={1}
                              style={[
                                {
                                  color: hovered ? color.actionChoose : "white",
                                  flex: 1,
                                },
                                styles.cell,
                              ]}
                            >
                              {e.action.name}
                            </Text>
                          </FullTextTooltip>
                          {/* description */}
                          <FullTextTooltip style={{ flex: 2 }} text={printDescription(e.action.description)}>
                            <Text
                              selectable={false}
                              numberOfLines={1}
                              style={[
                                {
                                  color: hovered ? color.actionChoose : "white",
                                  flex: 1.3,
                                },
                                styles.cell,
                              ]}
                            >
                              {printDescription(e.action.description)}
                            </Text>
                          </FullTextTooltip>
                          {/* time */}
                          <Text
                            selectable={false}
                            style={[
                              {
                                color: hovered ? color.actionChoose : "white",
                                flex: 0.8,
                              },
                              styles.cell,
                            ]}
                          >
                            {e.action.time &&
                              (() => {
                                let date1 = new Date()
                                let time = e.action.time.split(" ");
                                let date2 = new Date(`${time[0]}` + "T" + `${time[1]}` + "Z");
                                let clock = date2.toLocaleTimeString(date1.getTimezoneOffset(), { hour12: false, hour: "2-digit", minute: "2-digit" });
                                return date1.getDate() === date2.getDate() ? `${clock}` : date2.toDateString();
                              })()}
                          </Text>
                          {/* event */}
                          <FullTextTooltip style={{ flex: 1, }} text={events.find(event => event.id === e.action.event_id)?.title}>
                            <Text
                              selectable={false}
                              style={[
                                {
                                  color: hovered ? color.actionChoose : "white",
                                  flex: 1,
                                },
                                styles.cell,
                              ]}
                            >
                              {e.action.event_id ? ShortenWord(events.find(event => event.id === e.action.event_id)?.title, 8) : ""}
                            </Text>
                          </FullTextTooltip>
                          {/* shared */}
                          <Text
                            selectable={false}
                            style={[
                              {
                                color: hovered ? color.actionChoose : "white",
                                flex: 1,
                                position: "relative",
                                flexDirection: "column"
                              },
                              styles.cell,
                            ]}
                          >
                            <>
                              {e?.action?.share_list?.length > 0 &&
                                <Pressable
                                  style={{
                                    width: "4vw",
                                    height: "2vw",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {({ hovered: hoveredShare }) => (
                                    <>
                                      <Entypo name="share" size={widthS * 12} style={{ transform: [{ rotate: e.action.creator_id === (sessionStorage.getItem(USER_ID_IN_GAME_OBSERVER) || sessionStorage.getItem(USER_ID_IN_GAME)) ? 0 : "180deg" }] }} color={hovered || hoveredShare ? color.actionChoose : "white"} />
                                      {hoveredShare && <View nativeID="filter-shadow" style={{
                                        position: "absolute",
                                        bottom: widthS * 10,
                                        left: 0,
                                        padding: widthS * 5,
                                        borderRadius: widthS * 3,
                                        backgroundColor: background.popup1,
                                      }}>
                                        <Text
                                          selectable={false}
                                          style={[
                                            {
                                              color: hoveredShare ? color.actionChoose : "white",
                                            },
                                            styles.cell,
                                          ]}
                                        >
                                          {e.action.creator_id === sessionStorage.getItem(USER_ID_IN_GAME_OBSERVER) ? e.action.creator_id === sessionStorage.getItem(USER_ID_IN_GAME_OBSERVER) : e.action.creator_id === sessionStorage.getItem(USER_ID_IN_GAME) ? t("share_by") : t("share_me")}
                                        </Text>
                                      </View>}
                                    </>
                                  )}

                                </Pressable>}
                            </>
                          </Text>
                          {/* create_by */}
                          <FullTextTooltip style={{ flex: 1 }} text={findCreateName(e.action.creator_id, gameInfoArr?.players || [])}>
                            <Text
                              selectable={false}
                              style={[
                                {
                                  color: hovered ? color.actionChoose : "white",
                                  flex: 1,
                                },
                                styles.cell,
                              ]}
                            >
                              {findCreateName(e.action.creator_id, gameInfoArr?.players || [])}
                            </Text>
                          </FullTextTooltip>
                        </View>
                      )}
                    </Pressable>
                  );
                })}
            </View>
          </ScrollView>
        </View>
      </View>
    </>
  );
}

const stylesR = (width, height, heightS, widthS) =>
  StyleSheet.create({
    main: {
      display: "flex",
      alignItems: "center",
      borderRadius: widthS * 15,
      fontFamily: "Roboto",
      height: "100%",
    },
    header: {
      paddingLeft: widthS * 16,
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      justifyContent: "space-between",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    headerLeft: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingVertical: heightS * 4,
    },
    logo_view: {
      width: widthS * 26,
      height: widthS * 26,
      borderRadius: widthS * 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    logo: {
      width: widthS * 13,
      height: widthS * 13,
      resizeMode: "contain",
    },
    textLogo: {
      color: "white",
      fontSize: widthS * 15,
      paddingLeft: widthS * 10,
      fontFamily: "Catamaran_700Bold",
    },
    x: {
      fontWeight: "300",
      fontSize: widthS * 14,
      fontFamily: "Catamaran_400Regular",
    },
    new_btn: {
      position: "absolute",
      top: heightS * 6,
      right: "5vw",
      borderStyle: "solid",
      borderRadius: widthS * 5,
      paddingTop: heightS * 4,
      paddingBottom: heightS * 2,
      paddingHorizontal: widthS * 7,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    new_btn_text: {
      color: "#ffffff",
      fontSize: widthS * 10,
      fontFamily: "Catamaran_600SemiBold",
    },
    body: {
      marginVertical: heightS * 5,
    },
    table: {
      marginTop: heightS * 10,
      marginHorizontal: widthS * 3,
      position: "relative",
    },
    row_head: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      borderBottomColor: "#6b788659",
      borderBottomWidth: 1,
      borderStyle: "solid",
      paddingBottom: heightS * 3,
      marginHorizontal: widthS * 25,
      marginBottom: heightS * 3,
    },
    cell_head: {
      fontSize: widthS * 12,
      color: "white",
      textAlign: "center",
      fontFamily: "Catamaran_700Bold",
    },
    row: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      paddingBottom: heightS * 7,
      paddingTop: heightS * 7,
      paddingHorizontal: widthS * 25,
    },
    cell: {
      fontSize: widthS * 8,
      textAlign: "center",
      paddingHorizontal: "0.3em",
      fontFamily: "Catamaran_400Regular",
    },
  });
