import { StyleSheet, Text, View, ScrollView, Pressable, Modal } from 'react-native';
import React, { createRef, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { WordMapFuncContext } from '../analyticsFunction/WordsMapFunc';
import ReactWordcloud from "react-wordcloud";
import { useDispatch, useSelector } from 'react-redux';
import UseResponsiveSize from '../../../../modules/UseResponsiveSize';
import UseResponsiveScreen from '../../../../modules/UseResponsiveScreen';
import { t } from "i18n-js";
import TimeUnits from '../../../../Elements/TimeUnits';
import { AnalyticstimesContext } from '../analyticsFunction/Analyticstimes';
import TimesSlider from './TimesSlider';
import IconButton from '../../../../Elements/IconButton';

export default function WordsMap({ type, setWordsMessages, wordsMessages, setWord, word, timeUnits, setTimeUnits, isShot, refShot, stopPlayScroll, real, maximize, setMaximize }) {
    const options = {
        colors: ["#006ABA", "#FCF000", "#6A0084", "#017491", "#CC0053", "#DE82D8", "#CC822E", "#017491",],
        enableTooltip: false,
        deterministic: true,
        fontFamily: "Catamaran_600SemiBold",
        fontSizes: [20, 40, 60, 80, 100],
        fontStyle: "normal",
        fontWeight: "bold",
        padding: 2,
        rotations: 1,
        rotationAngles: [0, 90],
        scale: "sqrt",
        spiral: "archimedean",
        transitionDuration: 100
    };

    const {
        arrScore,
        arrScoreReal,
        filterStatistics,
        refresh,
        setRefresh,
        messages,
        messagesReal
    } = useContext(WordMapFuncContext);

    const {
        timeUnitsState,
        setTimeUnitsState,
    } = useContext(AnalyticstimesContext);

    const conatinerRef = useRef(null);
    const scrollRef = useRef([]);

    let countItems = 6;
    let id = "indicator-word";
    let flag = true;
    const [position, setPosition] = useState(0);

    const { currentTime, times } = TimesSlider({ type, scrollRef, conatinerRef, countItems, id, flag, position, setPosition, real });

    const { width, height } = UseResponsiveSize();
    const { widthS, heightS } = UseResponsiveScreen();
    const styles = stylesR(width, height, heightS, widthS);

    const dispatch = useDispatch();
    const scrollTimesRef = useRef(null);
    const timesRef = useRef(null);
    const prevTimeRef = useRef(null);

    const background = useSelector((state) => state.style.background);
    const color = useSelector((state) => state.style.color);
    const images = useSelector((state) => state.style.images);
    const lang = useSelector(state => state.setting.setting.lang);

    const [wordsState, setWordsState] = useState();
    const [messagesState, setMessgesState] = useState();

    useEffect(() => {
        if (type === "real" || (type === "compared" && real && Array.isArray(arrScoreReal))) {
            setWordsState([...arrScoreReal]);
            setMessgesState([...messagesReal]);
        } else {
            if (Array.isArray(arrScore)) {
                setWordsState([...arrScore]);
                setMessgesState([...messages]);
            }
        }
    }, [arrScore, arrScoreReal, messages, messagesReal]);

    useEffect(() => {
        if (times?.times?.length > 0) {
            refs = times?.times.reduce((acc, value) => {
                acc[value.id] = React.createRef();
                return acc;
            }, {});
        }
    }, [times]);

    const createMap = useCallback(() => {
        if (currentTime) {
            if (type === "compared" && real || type === "real") {
                filterStatistics(timeUnitsState, "real", currentTime, lang);
            } else if (type === "compared" && !real || type === "expected") {
                filterStatistics(timeUnitsState, "expected", currentTime, lang);
            }
        }
    }, [currentTime, timeUnitsState, type, lang, refresh]);

    useEffect(() => {
        // if (currentTime && !prevTimeRef.current) {
        createMap();
        // prevTimeRef.current = currentTime;
        // }
    }, [refresh, currentTime, lang, createMap]);

    // useEffect(() => {
    //     createArrayTimeDate(timeUnits, timesState?.count || 1, type);
    // }, [refreshTimes, type, timeUnits]);

    // useEffect(() => {
    //     if (position >= 0 && refs) {
    //         // if (position >= 0 && refs && timesState) {
    //         //     handleClick(timesState?.times[position].id, refs);
    //         // }
    //         let scroll = document.getElementById("scroll-time")?.scrollLeft;
    //         let item = refs[timesState?.times[position]?.id];
    //         if(item?.current){

    //         }
    //     }
    // }, [position, refs]);



    const getCallback = useCallback((callback) => {
        return function (word, event) {
            const isActive = callback !== "onWordMouseOut";
            if (isActive) {
                setWord(word);
            }
        };
    }, [setWordsMessages, setWord, messagesState]);

    // const setCurrentTimeFromScroll = (partOfScroll, scrollLeft, width) => {
    //     let count = Math.round(scrollLeft / partOfScroll);
    //     count = count >= timesState.times.length ? timesState.times.length - 1 : count;
    //     setTimeout(() => {
    //         // setPosition(count);
    //         // filterStatistics(timesState.count ? timesState.count + 1 : 1, type, count);
    //         createArrayTimeDate(timeUnits, timesState?.count + 1, type);
    //     }, 100)
    // }

    const callbacks = {
        onWordClick: getCallback("onWordClick"),
    };

    useEffect(() => {
        if (word) {
            const updatedWord = wordsState?.find((e) => e.text === word?.text);
            setWord(updatedWord);
        }
    }, [messagesState]);

    useEffect(() => {
        if (word) {
            const idsSet = new Set(word?.messageIds.map(idObj => idObj.id));
            const filteredMessages = messagesState.filter(message => idsSet.has(message.id));
            setWordsMessages(filteredMessages);
        }
    }, [word]);

    useEffect(() => {
        if (conatinerRef.current) {
            conatinerRef.current.addEventListener("mousemove", (e) => {
                stopPlayScroll(false);
            });
            conatinerRef.current.addEventListener("mouseleave", (e) => {
                stopPlayScroll(true);
            });
        }
    }, [conatinerRef.current]);

    const body = (isMaximize, isShot) => {
        return <>
            <View style={{ flexDirection: "row", zIndex: 1, justifyContent: "space-between", alignItems: "center" }}>
                <Text style={[styles.headers, { color: color.infoHeaderNavText }]}>{t("word_map").toUpperCase()}</Text>
                <View style={{ alignItems: "center", flexDirection: "row" }}>
                    {(type !== "compared" || real) && !maximize &&
                        <IconButton
                            flag={isMaximize}
                            iconName1={images.maximize}
                            iconName2={images.xIcon}
                            setFlag={() => setMaximize(!maximize)}
                            style={{ width: "0.8vw", height: "0.8vw", resizeMode: "contain" }}
                            style2={{ width: "1vw", height: "1vw", resizeMode: "contain", }}
                        />
                    }
                    {(real || type === "real") && <TimeUnits setTimeUnits={(t) => { setTimeUnitsState(t); setRefresh(!refresh) }} />}
                </View>
            </View>
            {/* table */}
            <View ref={conatinerRef} style={[styles.table, { backgroundColor: background.infoBox }]}>
                {/* <View
            style={{
                position: "relative",
                flexDirection: "row",
                backgroundColor: background.infoHeader,
                width: "100%",
                borderTopLeftRadius: widthS * 15,
                borderTopRightRadius: widthS * 15,
            }}>
            <View
                ref={scrollTimesRef}
                nativeID='scroll-time'
                onScroll={(e) => {
                    let scrollWidth = e.target.scrollWidth - e.target.clientWidth;
                    let partOfScroll = (scrollWidth / timesState?.times?.length);

                    if (e.currentTarget.scrollWidth - e.currentTarget.scrollLeft - 50 <= (e.currentTarget.clientWidth)) {
                        setCurrentTimeFromScroll(partOfScroll, e.target.scrollWidth - e.currentTarget.scrollLeft < 15 ? e.target.scrollWidth : e.target.scrollLeft, true);
                    } else {
                        // setCurrentTimeFromScroll(partOfScroll, e.target.scrollWidth - e.currentTarget.scrollLeft < 15 ? e.target.scrollWidth : e.target.scrollLeft, false);
                    }
                }}
                style={{
                    overflowX: "scroll",
                    overflowY: "hidden",
                    maxWidth: "100%",
                    borderTopLeftRadius: widthS * 15,
                    borderTopRightRadius: widthS * 15,
                }}
            >
                <View
                    style={{
                        flexDirection: "row",
                        alignItems: "center",
                        minWidth: "fit-content",
                        paddingHorizontal: widthS * 10,
                        borderTopLeftRadius: widthS * 15,
                        borderTopRightRadius: widthS * 15,
                    }}
                >
                    {timesState?.times?.length > 0 && timesState.times.map((e, i) => {
                        let date1, data2;
                        if (type === "real") {
                            date1 = new Date(timesState?.times[i]?.date).toString();
                            data2 = new Date(timesState?.times[position >= 0 ? position : 0]?.date).toString();
                        } else {
                            date1 = timesState?.times[i]?.date;
                            data2 = timesState?.times[position >= 0 ? position : 0]?.date;
                        }
                        return (
                            <Pressable
                                ref={(ref) => {
                                    if (!ref) return;
                                    if (position >= 0 && data2 === date1 && scrollTimesRef.current) {
                                        let t = ref.offsetLeft;
                                        scrollTimesRef.current.scrollLeft = t - 100
                                    }
                                }}
                                style={{
                                    marginHorizontal: widthS * 5,
                                    backgroundColor: date1 === data2 ? background.loginFooter : "",
                                    paddingVertical: widthS * 1,
                                    paddingHorizontal: widthS * 2,
                                    borderRadius: widthS * 3,
                                    marginVertical: widthS * 1,
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                                key={i}
                                onPress={async () => {
                                    setPosition(i);
                                    // filterStatistics(timesState?.count > 0 ? timesState?.count : 1, type, i);
                                }} >
                                <Text style={[styles.filterText, { color: color.infoHeaderNavText }]}>{type === "expected" && "T"}{e.time}</Text>
                            </Pressable>
                        )
                    })}
                </View>
            </View>
        </View> */}
                <TimesSlider
                    conatinerRef={conatinerRef}
                    countItems={countItems}
                    flag={false}
                    id={id}
                    scrollRef={scrollRef}
                    real={real}
                    type={type}
                    position={position}
                    setPosition={setPosition}
                />
                <View style={{ position: "relative", flex: 1, width: "100%" }}>
                    <View style={styles.levelBar}>
                        <Text style={[styles.filterText, { color: color.infoHeaderNavText, }]}>{t("word_usage")}: {t("low")}
                            <Text> A </Text>
                            <Text style={{ fontSize: "1.1vw", }}> A </Text>
                            {t("high")}</Text>
                    </View>
                    <ReactWordcloud
                        callbacks={callbacks}
                        options={options}
                        style={{ height: isMaximize ? "30vw" : isShot ? "40vw" : "31vw", width: "100%" }}
                        words={wordsState?.length > 30 ? wordsState : wordsState}
                    />
                </View>
            </View>
        </>
    }

    useEffect(() => {
        return () => {
            setWord();
            setMessgesState([])
        }
    }, []);

    return (
        <>
            <View nativeID='wordsMap' style={[styles.container, maximize && { height: "95%" }, type === "compared" && styles.conatinerCompared]}
            >
                {body(maximize)}
            </View>
        </>
    )
}

const stylesR = (width, height, heightS, widthS) => {
    return StyleSheet.create({
        container: {
            flex: 1,
            width: "100%",
            height: "41vw",
        },
        conatinerCompared: {
            maxWidth: "100%",
        },
        headers: {
            fontSize: "1.1vw",
            marginLeft: widthS * 6,
            marginBottom: heightS * 3,
            fontFamily: "Catamaran_300Light"
        },
        table: {
            width: "100%",
            borderRadius: widthS * 19,
            flexDirection: "column",
            alignItems: "center",
            height: "95%"
        },
        filterText: {
            fontSize: widthS * 8,
            marginLeft: widthS * 3,
            fontFamily: "Catamaran_300Light"
        },
        levelBar: {
            position: "absolute",
            top: "1.5vw",
            fontSize: widthS * 8,
            left: widthS * 15,
            fontFamily: "Catamaran_300Light"
        }
    });
}