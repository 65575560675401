import React from 'react';
import { useEffect } from 'react';
import { createContext } from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CheckTimeZone } from '../../../../modules/CheckTimeZone';
import { setTimeUnits } from '../../../../modules/createTimeColumns';

export const StatisticsFuncContext = createContext();

export default function StatisticsFuncProvider(props) {
    // selector
    const gameInfo = useSelector(state => state.gameInfoArr.gameInfoArr);

    const sendMailsSelector = useSelector(state => state.mailArr.sentMails);
    const mailsSelector = useSelector(state => state.mailArr.messages);
    const socialSelector = useSelector(state => state.socialMedia.socialMediaArr);
    const chatSelector = useSelector(state => state.chatArr.allMessages);
    const challengesSelector = useSelector(state => state.actionsArr.allChallenges);
    const actionsArrSelector = useSelector((state) => state.swimlane.actions);
    const eventsSelector = useSelector((state) => state.events.events);
    // select filter selector \\
    const actionSelectSelector = useSelector(state => state.analytics.filter.actions);
    const playersSelectSelector = useSelector(state => state.analytics.filter.players);
    const eventsSelectSelector = useSelector(state => state.analytics.filter.events);
    // end - selector\\
    // state - data
    const [mails, setMails] = useState([]);
    const [social, setSocial] = useState([]);
    const [chat, setChat] = useState([]);
    const [players, setPlayers] = useState([]);
    const [events, setEvents] = useState([]);
    const [actions, setActions] = useState([]);
    const [challenges, setChallenges] = useState([]);
    //\\
    // state - data to show \\
    // expected \\
    const [arrScore, setArrScore] = useState([]);
    const [maxCount, setMaxCount] = useState(0);
    // real \\
    const [arrScoreReal, setArrScoreReal] = useState([]);
    const [maxCountReal, setMaxCountReal] = useState(0);
    //\\
    const [refresh, setRefresh] = useState(false);
    const [filterPlatform, setFilterPlatform] = useState("mail,sent,chat,social,challenges,");

    // select filter
    useEffect(() => {
        setActions(actionsArrSelector);
        setRefresh(!refresh);
    }, [actionsArrSelector]);

    useEffect(() => {
        if (Array.isArray(sendMailsSelector) && sendMailsSelector.length > 0) {
            setMails([...sendMailsSelector.filter((e) => {
                if (e?.event?.name) {
                    if (eventsSelectSelector.indexOf(e?.event?.id) >= 0) {
                        return true;
                    }
                    return false;
                } else {
                    return true;
                }
            })]);
            setRefresh(!refresh);
        }
    }, [sendMailsSelector.length, eventsSelectSelector]);

    useEffect(() => {
        if (Array.isArray(mailsSelector) && mailsSelector.length > 0) {
            setMails([...mailsSelector.filter((e) => {
                if (e?.event?.name) {
                    if (eventsSelectSelector.indexOf(e?.event?.id) >= 0) {
                        return true;
                    }
                    return false;
                } else {
                    return true;
                }
            })]);
            setRefresh(!refresh);
        }

    }, [mailsSelector.length, eventsSelectSelector]);

    useEffect(() => {
        if (gameInfo?.players?.length > 0) {
            let arr = [...players];
            arr = gameInfo.players.filter((e) => playersSelectSelector.indexOf(e.id) >= 0 && e.permission_for_game === "15");
            setPlayers(arr);
            setRefresh(!refresh);
        }
    }, [playersSelectSelector, gameInfo?.players]);
    //\\

    useEffect(() => {
        if (challengesSelector.length > 0) {
            setChallenges([...challengesSelector.filter((e) => {
                if (e?.action?.event_id) {
                    if (eventsSelectSelector.indexOf(e?.action?.event_id) >= 0) {
                        return true;
                    }
                    return false;
                } else {
                    return true;
                }
            })]);
            setRefresh(!refresh);
        }
    }, [challengesSelector, eventsSelectSelector]);

    useEffect(() => {
        if (socialSelector.length > 0) {
            setSocial(socialSelector);
            setRefresh(!refresh);
        }
    }, [socialSelector.length]);

    useEffect(() => {
        if (chatSelector.length > 0) {
            setChat(chatSelector);
            setRefresh(!refresh);
        }
    }, [chatSelector.length]);

    useEffect(() => {
        if (events.length > 0) {
            setEvents(eventsSelector);
            setRefresh(!refresh);
        }
    }, [eventsSelector.length]);

    useEffect(() => {
        setRefresh(!refresh);
    }, [filterPlatform, actionSelectSelector]);

    const createArrStatistics = (players, events, actions, mails, social, chat, challenges, type, time, real, timeUnits) => {
        let statistics = [];
        switch (type) {
            case "expected":
                if (actions.length > 0) {
                    if (players.length > 0) {
                        players.forEach((e, i) => {
                            statistics.push({ name: e.name_for_game, id: e.id, score: {} });
                        });
                        return addScoreToPlayerExpected(statistics, actions, time?.time);
                    } else {
                        setMaxCount(0);
                        return setArrScore([]);
                    }
                } else {
                    setMaxCount(0);
                    return setArrScore([]);
                }
            case "real":
                if (players.length > 0) {
                    players.forEach((e, i) => {
                        statistics.push({
                            name: e.name_for_game,
                            id: e.id,
                            score: {}
                        });
                    });
                    return addScoreToPlayers(statistics, mails, social, chat, challenges, time?.date, type, timeUnits);
                } else {
                    setMaxCountReal(0);
                    return setArrScoreReal([]);
                }
            case "compared":
                if (players.length > 0 && actions?.length > 0) {
                    players.forEach((e, i) => {
                        statistics.push({
                            name: e.name_for_game,
                            id: e.id,
                            score: {}
                        });
                    });
                    if (real) {
                        return addScoreToPlayers(statistics, mails, social, chat, challenges, time?.date, type, timeUnits,);
                    } else {
                        return addScoreToPlayerExpected(statistics, actions, time?.step || time?.date, type);
                    }
                } else {
                    setMaxCountReal(0);
                    return setArrScoreReal([]);
                }

        }
    }

    // const createTimesCurrent = (timeUnits, chat, challenges, mails, social, typeArr, type, time, countSteps,) => {
    //     let timeArr = [];
    //     let { firstTime, endTime } = findLastTime(chat, challenges, mails, social);
    //     if (firstTime && timeUnits) {
    //         timeArr = setTimeUnits({ timeUnits: timeUnits, time: firstTime }, endTime, typeArr, +countSteps);
    //         if (type === "compared") {
    //             timeArr.forEach((e, i) => e.step = i + 1);
    //             setTimes({ count: typeArr > 0 ? typeArr : 1, times: timeArr });
    //         }
    //         setTimesReal({ count: typeArr > 0 ? typeArr : 1, times: timeArr });
    //     }
    //     return timeArr;
    // }

    const checkDate = (timeUnits, itemDate, date) => {
        let itemDateCurrent = CheckTimeZone(itemDate);
        if (timeUnits.indexOf("h") >= 0) {
            itemDateCurrent.setMinutes(0);
        }
        if (timeUnits.indexOf("d") >= 0) {
            itemDateCurrent.setMinutes(0);
            itemDateCurrent.setHours(0);
        }
        if (date) {
            if (itemDateCurrent > date) {
                return false;
            }
        }
        return true;
    }


    const addScoreToPlayerExpected = (statistics, arr, currentDateState, type) => {
        let currentDate = currentDateState;
        let mail = [];
        let social = [];
        if (Array.isArray(arr) && arr.length > 0 && statistics.length > 0) {
            arr.forEach((e) => {
                if (e.platform_type === "sm") {
                    social.push(e);
                } else if (e.platform_type === "mail" || e.platform_type === "") {
                    mail.push(e);
                }
            });
            let countLocal = 0;
            statistics.forEach((item, indexItem) => {
                let count = 0;
                if (mail.length > 0) {
                    let countMailSent = 0;
                    let countMail = 0;
                    let countDecitions = 0;
                    mail.forEach((mail, index) => {
                        if (currentDate && +mail.time <= currentDate || !currentDate) {
                            if (mail?.from_id === item.id) {
                                if (mail?.type_action === "Decision" && (actionSelectSelector.indexOf("challenges") >= 0 || actionSelectSelector.indexOf("Decisions") >= 0) && eventsSelectSelector.indexOf(mail?.event_id) >= 0) {
                                    countDecitions += 1;
                                } else if (actionSelectSelector.indexOf("Sent Items") >= 0 && eventsSelectSelector.indexOf(mail?.event_id) >= 0) {
                                    if (filterPlatform.indexOf("sent") >= 0) {
                                        countMailSent += 1;
                                    }
                                }
                            } else if (actionSelectSelector.indexOf("Incoming Items") >= 0 && eventsSelectSelector.indexOf(mail?.event_id) >= 0) {
                                if (filterPlatform.indexOf("mail") >= 0) {
                                    if (mail?.to_list_ids?.indexOf(item.id) >= 0 || mail?.to_list_ids?.indexOf(item.name) >= 0 ||
                                        mail?.cc_list_ids?.indexOf(item.id) >= 0 || mail?.cc_list_ids?.indexOf(item.name) >= 0 ||
                                        mail?.bcc_list_ids?.indexOf(item.id) >= 0 || mail?.bcc_list_ids?.indexOf(item.name) >= 0
                                    ) {
                                        countMail += 1;
                                    }
                                }
                            }
                        }
                    });
                    if (filterPlatform.indexOf("sent") >= 0) {
                        item.score.sent = countMailSent;
                        count += countMailSent;
                    } else {
                        item.score.sent = 0;
                    }
                    if (filterPlatform.indexOf("mail") >= 0) {
                        item.score.mail = countMail;
                        count += countMail;
                    } else {
                        item.score.mail = 0;
                    }
                    if (filterPlatform.indexOf("challenges") >= 0) {
                        item.score.challenges = countDecitions;
                        count += countDecitions;
                    } else {
                        item.score.challenges = 0;
                    }
                }
                if (social.length > 0) {
                    let countSocial = 0;
                    social.forEach((post) => {
                        if (actionSelectSelector.indexOf("Sent Items") >= 0) {
                            if (currentDate && +post.time <= currentDate || !currentDate) {
                                if (post.from_id === item.id && filterPlatform.indexOf("social") >= 0 && eventsSelectSelector.indexOf(post?.event_id) >= 0) {
                                    countSocial += 1;
                                }
                            }
                        }
                    });
                    item.score.social = countSocial;
                    count += countSocial;
                }
                if (count > countLocal) {
                    countLocal = count;
                }
            });
            setMaxCount(countLocal);
            if (type !== "compared") {
                // Sort by most active
                statistics = statistics.sort((a, b) => {
                    let aCount = 0;
                    let bCount = 0;
                    Object.keys(a.score).forEach((e) => {
                        aCount += a.score[e];
                    });
                    Object.keys(b.score).forEach((e) => {
                        bCount += b.score[e];
                    });
                    if (aCount > bCount) {
                        return -1
                    }
                });
            } else if (arrScoreReal?.length > 0) {
                // Create a map from arrScoreReal for easier lookup
                const scoreMap = new Map();
                arrScoreReal.forEach((player, index) => {
                    scoreMap.set(player.id, index);
                });

                // Sort statistics based on the order in arrScoreReal
                statistics = statistics.sort((a, b) => {
                    const aIndex = scoreMap.get(a.id) || 0;
                    const bIndex = scoreMap.get(b.id) || 0;
                    return aIndex - bIndex;
                });
            }
            return setArrScore([...statistics]);
        }
    }

    const addScoreToPlayers = (statistics, mails, social, chat, challenges, time, type, timeUnits) => {
        let datefilterCurrent = time ? new Date(time) : null;
        if (Array.isArray(statistics)) {
            let countLocal = 0;
            statistics.forEach((item, index) => {
                let count = 0;
                if (Array.isArray(mails) && mails.length > 0) {
                    let countMailSent = 0;
                    let countMail = 0;
                    mails.forEach((mail, index) => {
                        if (actionSelectSelector.indexOf("Sent Items") >= 0) {
                            if (mail?.sender?.id === item.id && filterPlatform.indexOf("sent") >= 0 && checkDate(timeUnits, mail.time, datefilterCurrent)) {
                                countMailSent += 1;
                            }
                        }
                        if (actionSelectSelector.indexOf("Incoming Items") >= 0 && filterPlatform.indexOf("mail") >= 0 && checkDate(timeUnits, mail.time, datefilterCurrent)) {
                            if ((mail?.to_list?.indexOf(item.id) >= 0 || mail?.to_list?.indexOf(item.name) >= 0) ||
                                (mail?.cc_list?.indexOf(item.id) >= 0 || mail?.cc_list?.indexOf(item.name) >= 0) ||
                                (mail?.bcc_list?.indexOf(item.id) >= 0 || mail?.bcc_list?.indexOf(item.name) >= 0)
                            ) {
                                countMail += 1;
                            }
                        }
                    });
                    if (filterPlatform.indexOf("sent") >= 0) {
                        item.score.sent = countMailSent;
                        count += countMailSent;
                    } else {
                        item.score.sent = 0;
                    }
                    if (filterPlatform.indexOf("mail") >= 0) {
                        item.score.mail = countMail;
                        count += countMail;
                    } else {
                        item.score.mail = 0;
                    }
                }
                if (Array.isArray(social) && social.length > 0 && filterPlatform.indexOf("social") >= 0) {
                    let countSocial = 0;
                    social.forEach((post, index) => {
                        if (actionSelectSelector.indexOf("Sent Items") >= 0) {
                            if (post.sender_by.id === item?.id && filterPlatform.indexOf("social") >= 0 && checkDate(timeUnits, post.time, datefilterCurrent)) {
                                countSocial += 1;
                            }
                        }
                    });
                    item.score.social = countSocial;

                    count += countSocial;
                }
                if (Array.isArray(chat) && chat.length > 0 && filterPlatform.indexOf("chat") >= 0 && actionSelectSelector.indexOf("Chat Messages") >= 0) {
                    let countChat = 0;
                    chat.forEach((chatItem) => {
                        if (item.id === chatItem.sender_id && filterPlatform.indexOf("chat") >= 0 && checkDate(timeUnits, chatItem.time, datefilterCurrent)) {
                            countChat += 1;
                        }
                    });
                    item.score.chat = countChat;

                    count += countChat;
                }
                if (Array.isArray(challenges) && challenges.length > 0 && filterPlatform.indexOf("challenges") >= 0 && actionSelectSelector.indexOf("Decisions") >= 0) {
                    let countChallenges = 0;
                    challenges.forEach((post, index) => {
                        if (post.action.creator_id?.indexOf(item?.id) >= 0 && checkDate(timeUnits, post.action.time, datefilterCurrent)) {
                            countChallenges += 1;
                        }
                    });
                    item.score.challenges = countChallenges;
                    count += countChallenges;
                }
                if (count > countLocal) {
                    countLocal = count;
                }
            });
            setMaxCountReal(countLocal);
            // if (type !== "compared") {
            statistics = statistics.sort((a, b) => {
                let aCount = 0;
                let bCount = 0;
                Object.keys(a.score).forEach((e) => {
                    aCount += a.score[e];
                });
                Object.keys(b.score).forEach((e) => {
                    bCount += b.score[e];
                });
                if (aCount > bCount) {
                    return -1
                }
            });
            // }
            return setArrScoreReal([...statistics]);
        }
    }

    // const createtimes = (times, arr, type) => {
    //     let arrN = [...times];
    //     if (arr.length > 0) {
    //         if (type === "chat") {
    //             arr?.forEach((e) => {
    //                 e.newMsg?.forEach((e) => {
    //                     arrN.push(e.time);
    //                 });
    //                 e.msgHistory?.forEach((e) => {
    //                     arrN.push(e.time);
    //                 });
    //             });
    //         } else if (type === "challenges") {
    //             arr?.forEach((e) => {
    //                 arrN.push(e.action.time);
    //             });
    //         } else {
    //             arr?.forEach((e) => {
    //                 arrN.push(e.time);
    //             });
    //         }
    //     }
    //     return arrN;
    // }

    // const returnIfIsThefirstOrLast = (timeCurrent, time, type) => {
    //     switch (type) {
    //         case "start":
    //             if (!timeCurrent) {
    //                 timeCurrent = new Date(time);
    //             } else if (time < timeCurrent) {
    //                 timeCurrent = new Date(time);
    //             }
    //             return timeCurrent;
    //         case "end":
    //             if (!timeCurrent) {
    //                 timeCurrent = new Date(time);
    //             } else if (time > timeCurrent) {
    //                 timeCurrent = new Date(time);
    //             }
    //             return timeCurrent;
    //     }
    // }

    // const findLastTime = (chatP, challengesP, mailsP, socialP) => {
    //     let firstTime;
    //     let endTime;
    //     if (chatP?.length > 0) {
    //         chatP?.forEach((e) => {
    //             firstTime = returnIfIsThefirstOrLast(firstTime, new Date(`${e.time}`), "start");
    //             endTime = returnIfIsThefirstOrLast(endTime, new Date(`${e.time}`), 'end');
    //         });
    //     }
    //     if (challengesP?.length > 0) {
    //         challengesP?.forEach((e) => {
    //             firstTime = returnIfIsThefirstOrLast(firstTime, new Date(`${e.action.time}`), "start");
    //             endTime = returnIfIsThefirstOrLast(endTime, new Date(`${e.action.time}`), 'end');
    //         });
    //     }
    //     if (mailsP?.length > 0) {
    //         mailsP?.forEach((e) => {
    //             firstTime = returnIfIsThefirstOrLast(firstTime, new Date(`${e.time}`), "start");
    //             endTime = returnIfIsThefirstOrLast(endTime, new Date(`${e.time}`), 'end');
    //         });
    //     }
    //     if (socialP?.length > 0) {
    //         socialP?.forEach((e) => {
    //             firstTime = returnIfIsThefirstOrLast(firstTime, new Date(`${e.time} gmt`), "start");
    //             endTime = returnIfIsThefirstOrLast(endTime, new Date(`${e.time} gmt`), 'end');
    //         });
    //     }
    //     return {
    //         firstTime,
    //         endTime
    //     };
    // }

    const filterStatistics = (type, time, real, timeUnits) => {
        if (type === "expected") {
            if (actions.length > 0) {
                createArrStatistics(
                    players,
                    events,
                    actions,
                    mails,
                    social,
                    chat,
                    challenges,
                    type,
                    time,
                    real,
                    timeUnits
                )
            } else {
                setMaxCount(0);
                setArrScore([]);
            }
        } else if (type === "real" || type === "compared") {
            if ((mails.length > 0 || chat.length > 0 || social.length > 0 || challenges.length > 0)) {
                createArrStatistics(
                    players,
                    events,
                    actions,
                    mails,
                    social,
                    chat,
                    challenges,
                    type,
                    time,
                    real,
                    timeUnits
                )
            } else {
                setMaxCountReal(0);
                setArrScoreReal([]);
            }
        }
    }

    return (
        <StatisticsFuncContext.Provider value={{
            arrScore,
            arrScoreReal,
            maxCount,
            maxCountReal,
            filterPlatform,
            setFilterPlatform,
            filterStatistics,
            refresh,
            setRefresh
        }}>
            {props.children}
        </StatisticsFuncContext.Provider>
    )
}
