export default function ShortenWord(string, count, file) {
  if (file) {
    let stringNew = string.slice(0, string.lastIndexOf("."));
    let type = string.slice(string.lastIndexOf("."));
    return stringNew.length > count ? `${stringNew.slice(0, count).trim()}... ${type}` : string;
  } else {
    return (
      string?.trim()?.length > count ? `${string.slice(0, count).trim()}...` : string
    )
  }
}