import io from 'socket.io-client';
import { PORT, HOST } from '@env';

class SocketManager {
    constructor() {
        if (!SocketManager.instance) {
            SocketManager.instance = this;
            // this.reconnectAttempts = 0; // מונה ניסיונות חיבור מחדש
            // this.maxReconnectAttempts = 4; // מספר ניסיונות החיבור לפני שנעבור לניסיון כל דקה
            // this.reconnectDelay = 2000; // 2 שניות
            // this.longReconnectDelay = 60000; // דקה (60 שניות)
            // this.isLongReconnect = false; // מסמן אם אנו במצב של חיבור פעם אחת כל דקה
            this._connectSocket();
        }
        return SocketManager.instance;
    }

    static resetInstance() {
        SocketManager.instance = null; // איפוס המופע הסטטי
    }

    _connectSocket() {
        if (this.socket) {
            this.socket.removeAllListeners();
            this.socket.disconnect();
        }

        this.socket = io(`https://${HOST}:${PORT}`, {
        // this.socket = io(`http://localhost:8050`, {
            transports: ['websocket', 'polling'],
            secure: true,
            timeout: 10000,
            reconnection: false, // לא נשתמש במנגנון החיבור מחדש הפנימי של Socket.IO
        });
        // this._setupEventListeners();
    }

    // _setupEventListeners() {
    //     this.socket.on('connect', () => {
    //         console.log('Connected to server');
    //         this.reconnectAttempts = 0; // איפוס הניסיונות בחיבור מוצלח
    //         this.isLongReconnect = false; // יציאה ממצב חיבור כל דקה
    //     });

    //     this.socket.on('connect_error', (error) => {
    //         console.error('Socket connection error:', error);
    //         this._handleReconnect();
    //     });

    //     this.socket.on('disconnect', () => {
    //         console.log('Socket disconnected');
    //         this._handleReconnect();
    //     });
    // }

    // _handleReconnect() {
    //     if (this.reconnectAttempts < this.maxReconnectAttempts) {
    //         // ננסה להתחבר עד 5 פעמים עם דיליי של 2 שניות
    //         this.reconnectAttempts++;
    //         console.log(`Reconnect attempt #${this.reconnectAttempts}, retrying in 2 seconds...`);
    //         setTimeout(() => {
    //             this._connectSocket();
    //         }, this.reconnectDelay);
    //     } else {
    //         // לאחר 5 ניסיונות כושלים נמתין דקה לניסיון הבא
    //         if (!this.isLongReconnect) {
    //             console.log('Max reconnect attempts reached. Retrying in 1 minute...');
    //         }
    //         this.isLongReconnect = true;
    //         setTimeout(() => {
    //             this._connectSocket();
    //         }, this.longReconnectDelay);
    //     }
    // }

    reconnectSocket() {
        this._connectSocket();
    }

    getSocketInstance() {
        return this.socket;
    }

    disconnectSocket() {
        if (this.socket) {
            this.socket.disconnect();
        }
    }
}

export { SocketManager };