import { Pressable, StyleSheet, Text, View, ScrollView } from 'react-native'
import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react'
import UseResponsiveSize from '../../../modules/UseResponsiveSize';
import UseResponsiveScreen from '../../../modules/UseResponsiveScreen';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18n-js';
import ComparedAnalytics from "./analyticsTypes/ComparedAnalytics";
import ExpectedAnalytics from "./analyticsTypes/ExpectedAnalytics";
import RealAnalytics from "./analyticsTypes/RealAnalytics";
import { showAnalyticsCompared, showAnalyticsExpected, showAnalyticsReal } from '../../../../redux/actions/creatorActions';
import "./style.css";
import ExportPopup from './ExportPopup';
import { useClickOutside } from '../../../modules/useClickOutside';
import { AntDesign } from '@expo/vector-icons';
import TextCustomize from '../../../Elements/TextCustomize';

const MyComponent = () => {

  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();

  const images = useSelector((state) => state.style.images);
  const background = useSelector((state) => state.style.background);
  const color = useSelector((state) => state.style.color);
  const styles = stylesR(width, height, heightS, widthS, background, color);

  const dispatch = useDispatch();

  const scrollRef = useRef(null);
  const exportRef = useRef(null);

  // export table shot ref
  const statisticsRef = useRef([null]);
  const heatMapRef = useRef([null]);
  const interactionRef = useRef([null]);
  const wordCloudRef = useRef([null]);
  const questionnaireRef = useRef([null]);
  const allQuestionnaireRef = useRef([null]);
  //

  const expectedFlag = useSelector(state => state.analytics.flags.expected);
  const realFlag = useSelector(state => state.analytics.flags.real);
  const comparedFlag = useSelector(state => state.analytics.flags.compared);

  const [flag, setFlag] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [isShot, setIsShot] = useState(false);
  const [num, setNum] = useState(1);
  const [disabled, setDisabled] = useState(false);

  useClickOutside(exportRef, showExport, () => setShowExport(false));

  const typesMaps = [
    { name: t("statistics_map"), num: 1 },
    { name: t("HEAT"), num: 2 },
    { name: t("INTERACTION"), num: 3 },
    { name: t("word_map"), num: 4 },
    { name: t("questionnaire_map"), num: 5 },
    { name: t("questionnaire_map"), num: 6 },
  ]

  const setMaximizeType = (current, type, setType) => {
    if (current) {
      setType(false);
    } else {
      setType(type)
    }
  }

  const typeAnalytics = () => {
    if (expectedFlag) {
      return (
        <ExpectedAnalytics
          statisticsRef={statisticsRef}
          heatMapRef={heatMapRef}
          interactionRef={interactionRef}
          questionnaireRef={questionnaireRef}
          allQuestionnaireRef={allQuestionnaireRef}
          wordCloudRef={wordCloudRef}
          stopPlayScroll={setDisabledScroll}
          isShot={isShot}
          setMaximizeType={setMaximizeType}
          num={num}
          setIsShot={setIsShot}
        />
      )
    } else if (realFlag) {
      return (
        <RealAnalytics
          statisticsRef={statisticsRef}
          heatMapRef={heatMapRef}
          interactionRef={interactionRef}
          wordCloudRef={wordCloudRef}
          questionnaireRef={questionnaireRef}
          allQuestionnaireRef={allQuestionnaireRef}
          stopPlayScroll={setDisabledScroll}
          isShot={isShot}
          setMaximizeType={setMaximizeType}
          num={num}
          setIsShot={setIsShot}
        />
      )
    } else {
      return (
        <ComparedAnalytics
          statisticsRef={statisticsRef}
          heatMapRef={heatMapRef}
          interactionRef={interactionRef}
          wordCloudRef={wordCloudRef}
          questionnaireRef={questionnaireRef}
          allQuestionnaireRef={allQuestionnaireRef}
          stopPlayScroll={setDisabledScroll}
          isShot={isShot}
          setMaximizeType={setMaximizeType}
          num={num}
          setIsShot={setIsShot}
        />
      )
    }
  }

  const setDisabledScroll = useCallback((flag) => {
    // if (flag) {
    //   setFlag(false);
    // } else {
    //   setFlag(true);
    // }
  }, []);

  const returnButton = (type, num) => {
    if (type === "up") {
      switch (num) {
        case 2: return
        case 3: return
        case 4: return
        case 5: return
      }
    } else {
      switch (num) {
        case 1: return t("HEAT")
        case 2: return t("INTERACTION")
        case 3: return t("word_map")
        case 4: return
      }
    }
  }

  // useEffect(() => {
  //   if (!isShot) {
  //     statisticsRef.current = [null];
  //     heatMapRef.current = [null];
  //     interactionRef.current = [null];
  //     wordCloudRef.current = [null];
  //   }
  // }, [isShot]);

  useEffect(() => {
    setIsShot(false);
  }, [expectedFlag, realFlag, comparedFlag]);

  useEffect(() => {
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 3000);
  }, [expectedFlag, realFlag, comparedFlag, num]);

  console.log("------------------------------------------------------------------------------------------------");

  return (
    <View style={{ backgroundColor: background.infoBody, flex: 1 }}>
      <View style={[styles.header]}>
        <View style={styles.headerLeft}>
          <View style={styles.navMaps}>
            {typesMaps.map((mapBtn, index) => {
              if (mapBtn.num === 6) return;
              return <Pressable style={styles.btn(mapBtn.num === num, num)} onPress={() => setNum(mapBtn.num)}>
                <TextCustomize fontSize={"1.3vw"} fontWeight={600} colorCurrent={mapBtn.num !== num ? color.placeholder : color.text2} text={mapBtn.name?.toUpperCase()} style={{ textAlign: "center", lineHeight: "1.4vw" }} />
              </Pressable>
            }
            )}
          </View>
          <View style={styles.navTypes}>
            {num !== 5 && <>
              <Pressable
                style={[styles.btnNav(expectedFlag)]}
                onPress={() => {
                  dispatch(showAnalyticsExpected(true));
                  dispatch(showAnalyticsCompared(false));
                  dispatch(showAnalyticsReal(false));
                  if (num === 5) {
                    setNum(1);
                  }
                }}
              >
                <Text style={[styles.btnNavText(expectedFlag)]}>{t("expected").toLocaleUpperCase()}</Text>
              </Pressable>
              <Pressable
                style={[styles.btnNav(realFlag)]}
                onPress={() => {
                  dispatch(showAnalyticsReal(true));
                  dispatch(showAnalyticsCompared(false));
                  dispatch(showAnalyticsExpected(false));

                }}
              >
                <Text style={[styles.btnNavText(realFlag)]}>{t("real").toUpperCase()}</Text>
              </Pressable>
              <Pressable
                style={[styles.btnNav(comparedFlag)]}
                onPress={() => {
                  dispatch(showAnalyticsCompared(true));
                  dispatch(showAnalyticsExpected(false));
                  dispatch(showAnalyticsReal(false));
                }}
              >
                <Text style={[styles.btnNavText(comparedFlag)]}>{t("COMPARED")}</Text>
              </Pressable>
            </>}
            {num !== 6 && <ExportPopup
              statisticsRef={statisticsRef}
              heatMapRef={heatMapRef}
              interactionRef={interactionRef}
              wordCloudRef={wordCloudRef}
              questionnaireRef={questionnaireRef}
              allQuestionnaireRef={allQuestionnaireRef}
              setIsShot={setIsShot}
              isShot={isShot}
              num={num}
              disabled={disabled}
              type={expectedFlag ? "expected" : realFlag ? "real" : "compared"}
            />}
          </View>
        </View>
        {/* <View ref={exportRef}>
          <Pressable style={styles.headerRight} onPress={() => setShowExport(!showExport)}>
            <Text style={[styles.btnNavText, { color: color.infoHeaderNavText }]}>{t("export").toLocaleUpperCase()}</Text>
          </Pressable>
          {showExport && <ExportPopup
            statisticsRef={statisticsRef}
            heatMapRef={heatMapRef}
            interactionRef={interactionRef}
            wordCloudRef={wordCloudRef}
            questionnaireRef={questionnaireRef}
            setIsShot={setIsShot}
            flag={showExport}
            type={expectedFlag ? "expected" : realFlag ? "real" : "compared"}
            setFlag={() => setShowExport(false)}
          />}
        </View> */}
      </View>
      {/* {num > 1 && <Pressable style={styles.btn("up")} onPress={() => setNum(num - 1)}>
        <AntDesign name="upcircleo" size={24} color={color.text2} />
        <TextCustomize text={returnButton("up", num)} />
      </Pressable>} */}
      <ScrollView
        nativeID={`${"chat-popup-g"}`}
        ref={scrollRef}
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          zIndex: 10,
        }}
        showsVerticalScrollIndicator={true}
        contentContainerStyle={{
          padding: "2.5vw",
          paddingHorizontal: widthS * 50,
        }}>
        {typeAnalytics()}
      </ScrollView>
      {/* {((expectedFlag && num < 4) || (!expectedFlag && num < 5)) && <Pressable style={styles.btn("down")} onPress={() => setNum(num + 1)}>
        <AntDesign name="downcircleo" size={24} color={color.text2} />
        <TextCustomize text={returnButton("down", num)} />
      </Pressable>} */}
    </View>
  )
}

const AnalyticsIndex = React.memo(MyComponent);

export default AnalyticsIndex;

const stylesR = (width, height, heightS, widthS, background, color) => {
  return StyleSheet.create({
    container: {

    },
    header: {
      display: "flex",
      flexDirection: "row",
      // alignItems: "center",
      justifyContent: "space-between",
      // paddingLeft: "5vw",
      height: "7vw",
      zIndex: 11
    },
    headerLeft: {
      display: "flex",
      width: "100%",
      gap: "1vw",
      // alignItems: "center",
    },
    navMaps: {
      display: "flex",
      flexDirection: "row",
      maxHeight: "4.5vw",
      height: "4.5vw",
      flex: 1,
      width: "100%"
    },
    navTypes: {
      display: "flex",
      flexDirection: "row",
      height: "2.5vw",
      maxHeight: "2.5vw",
      gap: "1vw",
      marginLeft: "5vw",
    },
    headerRight: {
      marginRight: "6vw",
      paddingHorizontal: "0.5vw",
      paddingVertical: "0.2vw",
      backgroundColor: color.buttonMenuLeft,
      borderRadius: "0.8vw",
    },
    btnNav: (flag) => ({
      width: "8vw",
      paddingVertical: "0.2vw",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "1vw",
      transition: "all 0.5s",
      ...(flag && { backgroundColor: background.loginFooter })
    }),
    btnNavText: (flag) => ({
      fontSize: "1.1vw",
      fontFamily: "Catamaran_500Medium",
      color: color.text2,
      ...(flag && {
        textDecorationColor: color.infoHeaderNavBorder,
        textDecorationLine: "underline",
        textDecorationStyle: "solid",
      })
    }),
    btn: (active, num) => ({
      flex: num === 5 ? 2 : 1,
      color: color.text2,
      alignItems: "center",
      justifyContent: "center",
      transition: "all 0.5s",
      backgroundColor: background.infoBox,
      ...(active && { backgroundColor: background.infoBody })
    }),
  })
}