import React, { useContext, useEffect, useRef, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  Pressable,
  Image,
  Dimensions,
} from "react-native";
import { USER_ID_IN_GAME } from "../../../config.inc";
import { useDispatch, useSelector } from "react-redux";
import { deleteChat, markChat } from "../../../redux/actions";
import { FontAwesome } from "@expo/vector-icons";
import UseResponsiveSize from "../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";
import { editDate } from "../../../EditDate";
import { ChatContext } from "../../../ContextData/Chat";
import FullTextTooltip from "../../Elements/FullTextTooltip";

export default function ChatListItem({
  item,
  index,
  setIndex,
  active,
  setNewData,
  scrollTo,
  id,
  setTo,
  userData,
  printDate,
  dashboard
}) {

  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);
  const styles = stylesR(background, color);

  const dispatch = useDispatch();
  const [message, setMessage] = useState({});
  const [count, setCount] = useState(0);
  const [messageItem, setMessageItem] = useState();
  const [activeState, setActiveState] = useState();

  const images = useSelector((state) => state.style.images);

  useEffect(() => {
    setMessageItem(item);
  }, [item]);

  useEffect(() => {
    setActiveState(active);
  }, [active]);

  return (
    <Pressable
      style={styles.container(index === active, dashboard)}
      onPress={() => {
        setIndex(id);
        setTo(id);
        scrollTo();
      }}
    >
      <View
        style={
          styles.containerChild(dashboard)
        }
      >
        <View style={styles.left}>
          <View style={styles.userImgView(dashboard)}>
            {messageItem && messageItem.group ? (
              <View
                style={styles.imgView(dashboard)}
              >
                <FontAwesome name="group" size={dashboard ? "1.5vw" : "2vw"} color="#252128" />
              </View>
            ) : (
              <Image
                source={userData && userData.profile_image ? userData.profile_image : images.user_image}
                style={styles.logo(dashboard)}
              />
            )
            }
          </View>
          <View style={styles.view}>
            <View style={styles.top(dashboard)}>
              <FullTextTooltip text={messageItem?.name || userData?.name_for_game} style={{ flex: 1 }}>
                <Text numberOfLines={1} selectable={false} style={styles.name(messageItem?.time, dashboard)}>
                  {messageItem?.name || userData?.name_for_game}
                </Text>
              </FullTextTooltip>
              <Text selectable={false} style={styles.time}>
                {messageItem && printDate(messageItem?.date, "list")}
              </Text>
            </View>
            <View style={styles.top(dashboard)}>
              <Text numberOfLines={1} selectable={false} style={styles.message(dashboard)}>
                {messageItem?.message?.replace(/\n/ig, "")}
              </Text>
              {messageItem?.count > 0 && activeState !== messageItem?.id && (
                <View
                  style={styles.count_view(dashboard)}
                >
                  <Text selectable={false} style={styles.count_text(dashboard)}>
                    {messageItem?.count}
                  </Text>
                </View>
              )}
            </View>
          </View>
        </View>
      </View>
    </Pressable>
  );
}
const stylesR = (background, color) =>
  StyleSheet.create({
    container: (flag, flag2) => ({
      height: flag2 ? "3vw" : "4.6vw",
      backgroundColor: flag ? background.chatMsgUserActive : "",
    }),
    containerChild: (flag) => ({
      display: "flex",
      height: flag ? "3vw" : "4.6vw",
      alignItems: "flex-start",
      justifyContent: "center",
      marginLeft: "1vw",
      paddingRight: "0.6vw",
      borderBottomWidth: 1,
      paddingVertical: "0.4vw",
      borderBottomColor: background.chatMsgUserActive
    }),
    left: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
    },
    userImgView: (flag) => ({
      backgroundColor: flag ? color.logoColor : "",
      padding: "0.1vw",
      borderRadius: "100%",
      marginRight: "0.8vw"
    }),
    imgView: (flag) => ({
      borderRadius: "100%",
      width: flag ? "2vw" : "2.5vw",
      height: flag ? "2vw" : "2.5vw",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#413b47",
    }),
    logo: (flag) => ({
      width: flag ? "1.8vw" : "2.5vw",
      height: flag ? "1.8vw" : "2.5vw",
      resizeMode: "cover",
      borderRadius: "2vw",
    }),
    view: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      flex: 1,
    },
    top: (flag) => ({
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      gap: "0.5em",
      height: flag ? "1vw" : "1.7vw",
    }),
    name: (flag, flag2) => ({
      fontSize: flag2 ? "0.8vw" : "0.9vw",
      fontFamily: "Catamaran_600SemiBold",
      color: "white",
      lineHeight: flag2 ? "1vw" : "1.5vw",
      maxWidth: flag ? "7.8vw" : "100%"
    }),
    message: (flag) => ({
      fontSize: flag ? "0.7vw" : "0.8vw",
      fontFamily: "Catamaran_400Regular",
      color: "white",
    }),
    time: {
      fontSize: "0.9vw",
      fontFamily: "Catamaran_400Regular",
      color: "white",
    },
    count_view: (flag) => ({
      width: flag ? "0.8vw" : "1.2vw",
      height: flag ? "0.8vw" : "1.2vw",
      borderRadius: "100%",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: background.chatCount
    }),
    count_text: (flag) => ({
      color: "black",
      fontSize: flag ? "0.6vw" : "1vw",
      fontFamily: "Catamaran_700Bold",
      padding: 0,
    }),
  });
